import React from "react";
import {Chip, Grid} from "@mui/material";
import {Show} from "../../utils/common";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ReplyIcon from '@mui/icons-material/Reply';

export const ExerciseSubheader = ({createdDuration, isNew, reads, solutions}) =>
    <Grid container spacing={1} alignItems="center">
        <Grid item>{createdDuration}</Grid>
        <Show value={!isNew && reads}>
            <Grid item>
                <Chip size="small"
                      variant="outlined"
                      icon={<VisibilityIcon fontSize="small"/>}
                      label={reads}/>
            </Grid>
        </Show>
        <Show value={!isNew && solutions}>
            <Grid item>
                <Chip size="small"
                      variant="outlined"
                      icon={<ReplyIcon fontSize="small"/>}
                      label={solutions}/>
            </Grid>
        </Show>
    </Grid>
