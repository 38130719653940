import React, {Component} from 'react';

import {styled} from '@mui/material/styles';

import PropTypes from 'prop-types';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import PersonIcon from '@mui/icons-material/Person';
import ExploreIcon from '@mui/icons-material/Explore';
import AddIcon from '@mui/icons-material/Add';
import {Link, withRouter} from "react-router-dom";
import {ADD_EXERCISE, EXPLORE_EXERCISE, HOME, VIEW_PROFILE_PREFIX} from "../../constants";
import Tooltip from "@mui/material/Tooltip";
import UserNotifications from "../../components/UserNotifications";
import SearchBox from "../../algolia/SearchBox";
import Grid from "@mui/material/Grid";
import Brightness7Icon from '@mui/icons-material/Brightness7';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import {ListItemAvatar, ListItemIcon, ListItemText} from "@mui/material";

const PREFIX = 'Bar';

const classes = {
    signUpButton: `${PREFIX}-signUpButton`,
    search: `${PREFIX}-search`,
    outlinedIcons: `${PREFIX}-outlinedIcons`,
};

const StyledAppBar = styled(AppBar)(({theme}) => {
    return {
        padding: '0 2px',
        transition: theme.transitions.create('width'),
        boxShadow: 'none',
        borderStyle: 'solid',
        borderColor:
            theme.palette.mode === 'dark' ? theme.palette.primaryDark[700] : theme.palette.grey[100],
        borderWidth: 0,
        borderBottomWidth: 'thin',
        background: theme.palette.mode === 'dark' ? theme.palette.primaryDark[900] : '#FFF',
        color: theme.palette.mode === 'dark' ? theme.palette.grey[500] : theme.palette.grey[800],
        '& .MuiIconButton-root': {
            border: `1px solid ${
                theme.palette.mode === 'dark' ? theme.palette.primaryDark[600] : theme.palette.grey[200]
            }`,
            borderRadius: theme.shape.borderRadius,
            color: theme.palette.mode === 'dark' ? '#FFF' : theme.palette.primary[500],
            background: theme.palette.mode === 'dark' ? theme.palette.primaryDark[800] : '#FFF',
        },
        [`& .${classes.search}`]: {
            padding: theme.spacing(1),
            [theme.breakpoints.up('lg')]: {
                paddingLeft: theme.spacing(15),
                paddingRight: theme.spacing(15),
            },
        },
        [`& .${classes.outlinedIcons}`]: {
            marginRight: theme.spacing(1)
        }
    };
});


class Bar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            menu: {
                anchorEl: null,
                notificationEl: null,
            },
            searchValue: '',
            notifications: [],
        };
    }


    openMenu = (event) => {
        const anchorEl = event.currentTarget;

        this.setState({
            menu: {
                anchorEl
            }
        });
    };


    closeMenu = () => {
        this.setState({
            menu: {
                anchorEl: null
            }
        });
    };

    handleSettingsClick = () => {
        this.closeMenu();
        this.props.onSettingsClick();
    };

    handleSignOutClick = () => {
        this.closeMenu();
        this.props.onSignOutClick();
        this.props.history.push(HOME);
    };

    render() {
        // Styling
        const {themeType, onThemeTypeChange} = this.props;

        // Properties
        const {title, isPerformingAuthAction, isSignedIn, user} = this.props;

        // Events
        const {onSignUpClick, onSignInClick} = this.props;

        const {menu} = this.state;

        return (
            <React.Fragment>
                <StyledAppBar
                    // color={themeType === 'light' ? 'white' : null}
                    elevation="0"
                    position="sticky">
                    <Toolbar>
                        <Grid container direction="row" alignItems="center">
                            <Grid item>
                                <Button edge="start" href={HOME}>
                                    <Typography variant="h6" style={{fontWeight: 900}} sx={{color: 'text.primary'}}>
                                        {title}
                                    </Typography>
                                </Button>
                            </Grid>
                            <Grid item xs>
                                <div style={{flexGrow: 1, color: 'inherit'}}>
                                    <Grid container direction="column" justifyContent="center" alignItems="stretch">
                                        <Grid item className={classes.search}>
                                            <SearchBox/>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>

                            <Grid item>
                                <Link to={EXPLORE_EXERCISE}>
                                    <Tooltip title="Exploreaza">
                                        <IconButton className={classes.outlinedIcons} aria-label="show 4 new mails"
                                                    size="small" sx={{px: "10px"}}>
                                            <ExploreIcon/>
                                        </IconButton>
                                    </Tooltip>
                                </Link>
                            </Grid>

                            <Grid item>
                                <Link to={ADD_EXERCISE}>
                                    <Tooltip title="Adauga intrebare">
                                        <IconButton className={classes.outlinedIcons} aria-label="add exercise"
                                                    size="small" sx={{px: "10px"}}>
                                            <AddIcon/>
                                        </IconButton>
                                    </Tooltip>
                                </Link>
                            </Grid>
                            <Grid item>
                                <Tooltip title={themeType === 'light' ? 'Tema dark' : 'Tema light'}>
                                    <IconButton
                                        className={classes.outlinedIcons}
                                        onClick={() => themeType === 'light' ?
                                            onThemeTypeChange({target: {value: 'dark'}}) :
                                            onThemeTypeChange({target: {value: 'light'}})}
                                        size="small" sx={{px: "10px"}}>
                                        {themeType === 'dark' ? <Brightness7Icon/> : <Brightness4Icon/>}
                                    </IconButton>
                                </Tooltip>
                            </Grid>

                            {isSignedIn &&
                            <Grid item>
                                <UserNotifications classes={classes}/>

                                <IconButton
                                    color="inherit"
                                    disabled={isPerformingAuthAction}
                                    onClick={this.openMenu}
                                    size="small" sx={{px: "10px"}}>
                                    {user.photoURL ? <Avatar alt="Avatar" src={user.photoURL}/> : <PersonIcon/>}
                                </IconButton>

                                <Menu anchorEl={menu.anchorEl} open={Boolean(menu.anchorEl)}
                                      onClose={this.closeMenu}>
                                    <MenuItem disabled={isPerformingAuthAction}
                                              onClick={() => {
                                                  this.closeMenu();
                                                  return this.props.history.push(VIEW_PROFILE_PREFIX + user.uid);
                                              }}>
                                        <ListItemAvatar>
                                            {user.photoURL ? <Avatar alt="Avatar" src={user.photoURL}/> : <PersonIcon/>}
                                        </ListItemAvatar>
                                        <ListItemText primary={user.displayName} secondary="Vezi profilul"/>
                                    </MenuItem>
                                    {/*<MenuItem disabled={isPerformingAuthAction}*/}
                                    {/*          onClick={this.handleSettingsClick}>*/}
                                    {/*    <ListItemIcon>*/}
                                    {/*        <SettingsIcon fontSize="small"/>*/}
                                    {/*    </ListItemIcon>*/}
                                    {/*    <ListItemText primary="Setari"/>*/}
                                    {/*</MenuItem>*/}
                                    <MenuItem disabled={isPerformingAuthAction}
                                              onClick={this.handleSignOutClick}>
                                        <ListItemIcon><ExitToAppIcon fontSize="small"/></ListItemIcon>
                                        <ListItemText primary="Deconectare"/>
                                    </MenuItem>
                                </Menu>
                            </Grid>
                            }

                            {!isSignedIn &&
                            <Grid item>
                                <Button className={classes.signUpButton}
                                        disabled={isPerformingAuthAction}
                                        onClick={onSignUpClick}>Inregistreaza-te</Button>
                                <Button disabled={isPerformingAuthAction}
                                        onClick={onSignInClick}>Conectare</Button>
                            </Grid>
                            }
                        </Grid>
                    </Toolbar>
                </StyledAppBar>
                <div ref={c => (this.sentinel = c)}/>
            </React.Fragment>
        );
    }
}

Bar.propTypes = {
    classes: PropTypes.object.isRequired,

    title: PropTypes.string.isRequired,
    isPerformingAuthAction: PropTypes.bool.isRequired,
    isSignedIn: PropTypes.bool.isRequired,

    onSettingsClick: PropTypes.func.isRequired,
    onSignOutClick: PropTypes.func.isRequired,
};

export default withRouter((Bar));
