import firebase from 'firebase/app';
import 'firebase/firebase-firestore';
import moment from "moment";

export const updateDisplayName = (uid, newDisplayName) => {
    firebase.firestore().collection('users').doc(uid).update({displayName: newDisplayName});
}

export const updateMessagingToken = (uid, token) => {
    firebase.firestore().collection('users').doc(uid).update({messagingToken: token});
}

export const updateLastSignIn = (uid) => {
    firebase.firestore().collection('users').doc(uid)
        .update({lastSignInTime: moment.now()});
}

export const fetchUser = (uid) => {
    return firebase.firestore().collection('users').doc(uid).get();
}
