import React, {useEffect, useState} from 'react';
import {fetchExercises} from "../../service/exercise.service";
import {NAVIGATION} from "../Explore/ExerciseNavigator";
import _ from "lodash";
import {Avatar, Grid, Typography} from "@mui/material";
import {Exercise} from "../ExerciseListContent/Exercise";


export const HelpWanted = () => {
    const [exercise, setExercise] = useState(null);

    useEffect(() => {
        fetchExercises(NAVIGATION.unanswered, null, 1)
            .then((resp) => {
                let docs = _.get(resp, 'docs', []);
                if (docs && docs.length > 0) {
                    let ex = docs[0].data();
                    setExercise({...ex, id: docs[0].id});
                }
            })
            .catch();
    }, [])

    if (!exercise) {
        return null;
    }

    return (
        <Grid container direction="column">
            <Grid item container justifyContent="center" alignItems="center">
                <Grid item>
                    <Avatar sx={{width: 56, height: 56}} src={exercise.userPhotoUrl}/>
                </Grid>
                <Grid item>
                    <Typography variant="h3">{exercise.userDisplayName}</Typography>
                </Grid>
                <Grid item sx={{marginLeft: (theme) => theme.spacing(1)}}>
                    <Typography variant="h4"> ar avea nevoie de ajutorul
                        tau:</Typography>
                </Grid>
            </Grid>
            <Grid container justifyContent="center">
                <Grid item xs={12} md={10} lg={8}>
                    <Exercise exId={exercise.id} isNew={false} showDetails={false}/>
                </Grid>
            </Grid>
        </Grid>
    );
};
