import React, {useEffect} from 'react';
import {useParams} from "react-router-dom";
import {Exercise} from "../ExerciseListContent/Exercise";
import {content, track} from "../../utils/analytics";

export const ExerciseDetails = ({onMessage, user, onSignInClick}) => {
    const {exerciseId} = useParams();

    useEffect(() => {
        track(content.viewExercise, {exId: exerciseId, userId: user?.uid, userDisplayName: user?.displayName})
        // eslint-disable-next-line
    }, [])

    return (
        exerciseId ? <Exercise exId={exerciseId} onMessage={onMessage} showDetails={true} user={user}
                               onSignInClick={onSignInClick}/> : null
    );
}
