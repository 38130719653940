import React, {useState} from "react";
import {styled} from '@mui/material/styles';
import Grid from "@mui/material/Grid";
import Vote from "../content/ExerciseListContent/Vote";
import Button from "@mui/material/Button";
import firebase from 'firebase/app';
import 'firebase/firebase-firestore';
import {convertToRaw} from "draft-js";
import {
    extractUser,
    getEditorStateFromString,
    getStringFromEditorState,
    humanizeDate,
    isEmptyDraftJs,
    isSameUserOrAdmin
} from "../utils/common";
import IconButton from "@mui/material/IconButton";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import Typography from "@mui/material/Typography";
import {TexEditor} from "./TexEditor";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import CardHeader from "@mui/material/CardHeader";
import Avatar from "@mui/material/Avatar";
import PersonIcon from "@mui/icons-material/Person";
import Tooltip from "@mui/material/Tooltip";
import {FileUploadAction} from "./FileUploadAction";
import {ThemeProvider} from "@mui/styles";
import {StyledEngineProvider} from "@mui/styled-engine";
import {redGreenTheme} from "../themes";
import {useHistory} from "react-router-dom";
import {VIEW_PROFILE_PREFIX} from "../constants";
import {ConditionalCardActionArea} from "./ConditionalCardActionArea";


const PREFIX = 'Solution';

const classes = {
    button: `${PREFIX}-button`,
    solution: `${PREFIX}-solution`,
    noPaddingForMobile: `${PREFIX}-noPaddingForMobile`
};

const StyledCard = styled(Card)((
    {
        theme
    }
) => ({
    [`& .${classes.button}`]: {
        marginTop: theme.spacing(2)
    },

    [`&.${classes.solution}`]: {
        marginTop: theme.spacing(3),
        backgroundColor: 'inherit'
    },

    [`& .${classes.noPaddingForMobile}`]: {
        [theme.breakpoints.down('xl')]: {
            paddingLeft: 0,
            paddingRight: 0,
        }
    }
}));


export {};


function hasAlreadyVoted(votes, user) {
    if (!votes) return false;
    if (!user) return false;
    return votes.filter(v => v.userUUID === user.uid).length > 0;
}

export default function Solution({ex, solution, isLast, onMessage, user, onChanged, onSignInClick, isBestSolution}) {

    const history = useHistory();

    const [solutionEditorState, setSolutionEditorState] = useState(getEditorStateFromString(solution.content));

    const {userPhotoUrl, userDisplayName, created} = solution;
    const sameUser = isSameUserOrAdmin(user, solution);
    const {votes} = solution || [];
    const alreadyVoted = hasAlreadyVoted(votes, user);

    let photoURL = user ? (isLast ? user.photoURL : userPhotoUrl) : userPhotoUrl;
    let displayName = user ? (isLast ? user.displayName : userDisplayName) : userDisplayName;

    //TODO already voted
    return (
        <StyledCard elevation={0} className={classes.solution}>
            <ConditionalCardActionArea
                enabled={!isLast}
                onClick={() => history.push(VIEW_PROFILE_PREFIX + ((solution && solution.userUUID) || user.uid))}>
                <CardHeader
                    avatar={photoURL ? <Avatar alt="Avatar" src={photoURL}/> : <Avatar><PersonIcon/></Avatar>}
                    title={displayName}
                    subheader={humanizeDate(created)}/>
            </ConditionalCardActionArea>
            <CardActions>
                {sameUser &&
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={redGreenTheme}>
                        <Tooltip title="Salveaza solutia">
                            <IconButton
                                aria-label="save"
                                color="primary"
                                onClick={saveSolution(ex, solution, onMessage, solutionEditorState, onChanged)}
                                size="large">
                                <SaveIcon/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Sterge solutia">
                            <IconButton
                                aria-label="delete"
                                color="secondary"
                                onClick={removeSolution(ex, solution, onMessage, onChanged)}
                                size="large">
                                <DeleteIcon/>
                            </IconButton>
                        </Tooltip>
                    </ThemeProvider>
                </StyledEngineProvider>
                }
                {(sameUser || isLast) &&
                <FileUploadAction editorState={solutionEditorState}
                                  setEditorState={setSolutionEditorState}/>}
            </CardActions>

            <CardContent className={classes.noPaddingForMobile}>
                {isLast && <Typography variant="h5">Raspunsul tau</Typography>}
                <Grid container>
                    {!isLast &&
                    <Grid item xs={12} md={1}>
                        <Vote number={votes ? votes.length : 0} isSolution={isBestSolution} solution={solution}
                              alreadyVoted={alreadyVoted}/>
                    </Grid>
                    }
                    <Grid item xs={12} md={isLast ? 12 : 11}>
                        <TexEditor editorState={solutionEditorState}
                                   advanced={true}
                                   isNew={isLast}
                                   isSolution={true}
                                   readOnly={!isLast && !sameUser}
                                   onChange={(newEditorStates) => {
                                       setSolutionEditorState(newEditorStates);
                                   }}/>
                        {
                            isLast &&
                            <Grid container direction="row" justifyContent="flex-end">
                                <Grid item>
                                    <Button className={classes.button} variant="contained" color="primary"
                                            onClick={insertSolution(user, onMessage, solutionEditorState, ex, onChanged, onSignInClick)}>
                                        Posteaza raspunsul tau
                                    </Button>
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                </Grid>
            </CardContent>
        </StyledCard>
    );
}

function saveSolution(ex, solution, onMessage, solutionEditorState, onChanged) {
    return () => {
        firebase.firestore().collection('exercise').doc(ex.id)
            .collection("solutions")
            .doc(solution.id)
            .update({content: getStringFromEditorState(solutionEditorState)})
            .then(() => {
                onMessage('Solutia a fost salvata');
                onChanged(true);
            })
            .catch(err => {
                console.log(err);
                onMessage('A aparut o problema la salvarea solutiei');
            });
    }
}

function removeSolution(ex, solution, onMessage, onChanged) {
    return () => {
        firebase.firestore().collection('exercise').doc(ex.id)
            .collection("solutions")
            .doc(solution.id).delete()
            .then(() => {
                onMessage('Solutia a fost stearsa');
                onChanged(true);
            })
            .catch(err => {
                console.log(err);
                onMessage('A aparut o problema la stergerea solutiei');
            });
    }
}

function insertSolution(user, onMessage, solutionEditorState, ex, onChanged, onSignInClick) {
    return () => {
        if (!user) {
            onSignInClick();
            onMessage('Va rog sa va autentificati mai intai');
            return;
        }

        if (isEmptyDraftJs(convertToRaw(solutionEditorState.getCurrentContent()))) {
            onMessage('Trebuie sa completati raspunsul mai intai');
            return;
        }
        firebase.firestore().collection('exercise').doc(ex.id).collection("solutions").add({
            content: getStringFromEditorState(solutionEditorState),
            ...extractUser(user),
            created: firebase.firestore.FieldValue.serverTimestamp(),
        }).then(async () => {
            onMessage('Solution was saved');
            onChanged(true);
        }).catch(error => console.log(error))
    };
}
