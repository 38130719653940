import React from 'react';
import { styled } from '@mui/material/styles';
import {Grid, Typography} from "@mui/material";
import { alpha } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import NotificationsIcon from "@mui/icons-material/Notifications";

const PREFIX = 'HowItWorks';

const classes = {
    howItWorks: `${PREFIX}-howItWorks`,
    title: `${PREFIX}-title`,
    iconTitle: `${PREFIX}-iconTitle`,
    subTitle: `${PREFIX}-subTitle`,
    icon: `${PREFIX}-icon`
};

const StyledGrid = styled(Grid)((
    {
        theme
    }
) => ({
    [`&.${classes.howItWorks}`]: {
        paddingTop: theme.spacing(10),
        paddingBottom: theme.spacing(10),
        flexGrow: 1,
    },

    [`& .${classes.title}`]: {
        fontWeight: 900
    },

    [`& .${classes.iconTitle}`]: {
        fontWeight: 600,
        marginTop: theme.spacing(2),
    },

    [`& .${classes.subTitle}`]: {
        fontWeight: 300,
        marginTop: theme.spacing(2),
    },

    [`& .${classes.icon}`]: {
        padding: theme.spacing(2),
        backgroundColor: alpha(theme.palette.primary.main, 0.2),
        borderRadius: "16px",
        flexGrow: 0,
    }
}));

export const HowItWorks = () => {


    return (
        <StyledGrid container justifyContent="center" className={classes.howItWorks} spacing={3}>
            <Grid item>
                <Typography variant="h3" className={classes.title}>Cum functioneaza?</Typography>
            </Grid>
            <Grid item xs={12} container direction="row" justifyContent="center" className={classes.howItWorks}>
                <Grid item xs={9} md={3}>
                    <SearchIcon color="primary" fontSize="large" className={classes.icon}/>
                    <Typography variant="h5" className={classes.iconTitle}>Cauta intrebarea</Typography>
                    <Typography variant="subtitle1" className={classes.subTitle}>Introdu cifrele sau textele cele mai
                        specifice
                        pentru rezultate mai bune.</Typography>
                </Grid>
                <Grid item xs={9} md={3}>
                    <AddIcon color="primary" fontSize="large" className={classes.icon}/>
                    <Typography variant="h5" className={classes.iconTitle}>Adauga intrebarea</Typography>
                    <Typography variant="subtitle1" className={classes.subTitle}>Folosind editorul specializat se poate
                        introduce orice ecuatie matematica, sau daca nu te descurci adauga o poza.</Typography>
                </Grid>
                <Grid item xs={9} md={3}>
                    <NotificationsIcon color="primary" fontSize="large" className={classes.icon}/>
                    <Typography variant="h5" className={classes.iconTitle}>Notificare</Typography>
                    <Typography variant="subtitle1" className={classes.subTitle}>Primesti notificare atunci cand cineva
                        a adaugat un raspuns la exercitiul introdus.</Typography>
                </Grid>
            </Grid>

        </StyledGrid>
    );
}
