export const lessons = [
    {
        id: '1',
        name: 'Ecuatii de gradul I cu o necunoscuta', grade: 'VII',
    },
    {
        id: '2', name: 'Ecuatii de gradul I cu doua necunoscute', grade: 'VII',
    },
    {
        id: '3', name: 'Sisteme de doua ecuatii de gradul I cu doua necunoscute', grade: 'VII',
    },
    {
        id: '4',
        name: 'Probleme care se rezolva cu ajutorul ecuatiilor sau ale sistemelor de ecuatii liniare',
        grade: 'VII',
    },
    {
        id: '6',
        name: 'Produsul cartezian a doua multimi nevide. Sistem de axe ortogonale in plan. Reprezentarea puntelor intr-un sistem de axe ortogonale. Distanta dintre doua puncte in plan',
        grade: 'VII',
    },
    {
        id: '7',
        name: 'Dependenta functionala. Reprezentarea si interpretarea unor dependente functionale prin tabele, diagrame si grafice',
        grade: 'VII',
    },
    {
        id: '8', name: 'Elemente de statistica matematica', grade: 'VII',
    },
    {
        id: '9', name: 'Teorema inaltimii', grade: 'VII',
    },
    {
        id: '10', name: 'Teorema catetei', grade: 'VII',
    },
    {
        id: '11', name: 'Teorema lui Pitagora', grade: 'VII',
    },
    {
        id: '12', name: 'Notiuni de trigonometrie', grade: 'VII',
    },
    {
        id: '13', name: 'Aria triunghiului. Rezolvarea triunghiului dreptunghic', grade: 'VII',
    },
    {
        id: '14',
        name: 'Calculul elementelor in triunghiul echilatelar, in patrat si in hexagonul regulat',
        grade: 'VII',
    },
    {
        id: '15', name: 'Aria patrulaterului', grade: 'VII',
    },
    {
        id: '16', name: 'Probleme de matematica aplicata in viata cotidiana', grade: 'VII',
    },
    {
        id: '17', name: 'Modele de teze semestriale I', grade: 'VII',
    },
    {
        id: '18', name: 'Modele de teze semestriale II', grade: 'VII',
    },
    {
        id: '19', name: 'Teste', grade: 'VII',
    },
    {
        id: '20', name: 'Test de autoevaluare', grade: 'VII',
    },
    {
        id: '21', name: 'Recapitulare si evaluare finala', grade: 'VII',
    },
];




