import React from "react";
import {Button, Grid, ToggleButton, ToggleButtonGroup, Typography} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import {useHistory} from "react-router-dom";
import {ADD_EXERCISE} from "../../constants";

const PREFIX = 'NAVIGATION';

const classes = {
    marginTop: `${PREFIX}-marginTop`
};

export const NAVIGATION = {
    newest: 'newest',
    unanswered: 'unanswered'
}

export default function ExerciseNavigator({navigation, onChanged, disabled}) {
    let history = useHistory();


    return (
        <React.Fragment>
            <Typography variant="h3">Exploreaza intrebarile</Typography>
            <Grid container justifyContent="space-between" sx={{marginTop: (theme) => theme.spacing(2)}}>
                <Grid item>
                    <ToggleButtonGroup
                        value={navigation}
                        exclusive
                        onChange={(e, nav) => onChanged(nav)}
                        aria-label="exercise navigation">
                        <ToggleButton value={NAVIGATION.newest} aria-label="left aligned" disabled={disabled}>
                            <Typography variant="subtitle2">Cele mai noi</Typography>
                        </ToggleButton>
                        <ToggleButton value={NAVIGATION.unanswered} aria-label="right aligned" disabled={disabled}>
                            <Typography variant="subtitle2">Fara raspuns</Typography>
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Grid>
                <Grid item className={classes.marginTop}>
                    <Button variant="contained"
                            color="primary"
                            startIcon={<AddIcon/>}
                            onClick={() => history.push(ADD_EXERCISE)}>
                        <Typography variant="button">Adauga o intrebare</Typography>
                    </Button>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}
