import React, {Component} from 'react';

import {styled} from '@mui/material/styles';

import PropTypes from 'prop-types';

import Grid from "@mui/material/Grid";
import 'firebase/firebase-firestore';
import 'firebase/firebase-auth';
import {withRouter} from "react-router";
import {Exercise} from "../ExerciseListContent/Exercise";

const PREFIX = 'AddExerciseContent';

const classes = {
    grid: `${PREFIX}-grid`,
    button: `${PREFIX}-button`,
    input: `${PREFIX}-input`,
    editor: `${PREFIX}-editor`
};

const StyledGrid = styled(Grid)((
    {
        theme
    }
) => ({
    [`&.${classes.grid}`]: {
        padding: theme.spacing(1),
    },

    [`& .${classes.button}`]: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1)
    },

    [`& .${classes.input}`]: {
        display: 'none'
    },

    [`& .${classes.editor}`]: {
        margin: theme.spacing(2)
    },
    minHeight: '100vh',
}));

class AddExerciseContent extends Component {

    render() {
        const {onMessage, user, onSignInClick} = this.props;

        return (
            <StyledGrid container className={classes.grid} justifyContent="center">
                <Grid item xs={12} md={10}>
                    <Exercise onMessage={onMessage} user={user} isNew={true} onSignInClick={onSignInClick}/>
                </Grid>
            </StyledGrid>
        );
    }
}

AddExerciseContent.propTypes = {
    classes: PropTypes.object.isRequired,
    isSignedIn: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    user: PropTypes.object,
    onError: PropTypes.func,
    onMessage: PropTypes.func,
    history: PropTypes.object.isRequired
};

export default withRouter((AddExerciseContent));
