import React, {useContext, useState} from "react";
import { styled } from '@mui/material/styles';
import {SimpleEditorComponent} from "./SimpleEditorComponent";
import Button from "@mui/material/Button";
import {ExerciseContext} from "./Exercise";
import {EMPTY_EQUATION, EMPTY_TEXT} from "../../constants";
import {mergeTexts, Show, uuid} from "../../utils/common";
import AddIcon from "@mui/icons-material/Add";
import _ from 'lodash';
import Divider from "@mui/material/Divider";

const PREFIX = 'SimpleEditor';

const classes = {
    toolbox: `${PREFIX}-toolbox`
};

const StyledShow = styled(Show)((
    {
        theme
    }
) => ({
    [`& .${classes.toolbox}`]: {
        flexGrow: 1,
        paddingBottom: theme.spacing(1),
    }
}));


export const SimpleEditor = ({editorState, onChange}) => {
    const {setSimpleEditorState} = useContext(ExerciseContext);
    const [focusedIndex, setFocusedIndex] = useState(2);

    function addEquation() {
        return () => {
            let insertIndex = focusedIndex;
            const state = editorState[focusedIndex];
            if (state && state.value === '') {
                insertIndex--;
            }

            editorState.splice(insertIndex + 1, 0, EMPTY_EQUATION);
            if (insertIndex + 2 >= editorState.length) {
                editorState.splice(insertIndex + 2, 0, EMPTY_TEXT);
            }
            setSimpleEditorState(_.cloneDeep(editorState.map(field => {
                field.uuid = uuid(); //regenerate uuid in order to force updating the state
                return field;
            })));
        };
    }

    function removeEquation() {
        return (indexToRemove) => {
            editorState.splice(indexToRemove, 1);

            const mergedEditorState = mergeTexts(editorState);
            setSimpleEditorState(_.cloneDeep(mergedEditorState.map(field => {
                field.uuid = uuid();
                return field;
            })));
        };
    }

    return (
        <StyledShow value={!!editorState}>
            <div className={classes.toolbox}>
                <Button startIcon={<AddIcon/>} onClick={addEquation()}>Ecuatie</Button>
                <Divider/>
            </div>
            {editorState && editorState.map((state, index) =>
                <SimpleEditorComponent key={state.uuid} state={state} index={index}
                                       onFocus={(index) => setFocusedIndex(index)}
                                       focusedIndex={focusedIndex}
                                       onChange={onChange}
                                       onRemove={removeEquation()}/>)}
        </StyledShow>
    );

}