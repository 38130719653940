import React from 'react';
import {styled} from '@mui/material/styles';
import {Button, Grid, Slide, Typography} from "@mui/material";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import QuestionAnswerOutlinedIcon from "@mui/icons-material/QuestionAnswerOutlined";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {green} from "@mui/material/colors";
import {useHistory} from "react-router-dom";
import {ADD_EXERCISE, EXPLORE_EXERCISE} from "../../constants";

const PREFIX = 'Banner';

const classes = {
    textPadding: `${PREFIX}-textPadding`,
    callToAction: `${PREFIX}-callToAction`,
    wrapper: `${PREFIX}-wrapper`
};

const StyledGrid = styled(Grid)((
    {
        theme
    }
) => ({
    [`& .${classes.textPadding}`]: {paddingLeft: theme.spacing(1)},
    [`& .${classes.callToAction}`]: {marginTop: theme.spacing(2)},
    [`&.${classes.wrapper}`]: {paddingBottom: theme.spacing(5)}
}));

export const Banner = () => {

    const history = useHistory();

    return (
        <StyledGrid container alignItems="center" justifyContent="center" className={classes.wrapper}>
            <Slide direction="right" in={true} mountOnEnter unmountOnExit timeout={700}>
                <Grid item xs={12} md={5} container spacing={3} padding={3}>
                    <Grid item>
                        <Typography variant="h2" color="textPrimary"
                                    style={{fontWeight: 900}}>MathClub&nbsp;</Typography>
                        <Typography variant="h6" color="textSecondary" style={{fontWeight: 500}}>este un site de tipul
                            intrebari-raspunsuri
                            pentru elevi
                        </Typography>
                    </Grid>

                    <Grid item container alignItems="center">
                        <QuestionAnswerIcon fontSize="large"/>
                        <Typography variant="subtitle1" className={classes.textPadding}>
                            Oricine poate sa puna o intrebare
                        </Typography>
                    </Grid>
                    <Grid item container alignItems="center">
                        <QuestionAnswerOutlinedIcon fontSize="large"/>
                        <Typography variant="subtitle1" className={classes.textPadding}>
                            Oricine poate sa raspunda
                        </Typography>
                    </Grid>

                    <Grid item container direction="row" alignItems="center">
                        <Grid container direction="column" item xs={1}>
                            <ArrowDropUpIcon fontSize="large" style={{color: green[500]}}/>
                            <ArrowDropDownIcon fontSize="large"/>
                        </Grid>
                        <Grid item xs>
                            <Typography variant="subtitle1" className={classes.textPadding}>
                                Cele mai bune raspunsuri sunt votate si ridicate in top
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid item container spacing={1} className={classes.callToAction}>
                        <Grid item>
                            <Button color="primary"
                                    variant="contained"
                                    onClick={() => history.push(EXPLORE_EXERCISE)}>Exploreaza</Button>
                        </Grid>
                        <Grid item>
                            <Button color="primary"
                                    variant="contained"
                                    onClick={() => history.push(ADD_EXERCISE)}>Adauga o intrebare</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Slide>
            <Grid item xs={12} md={5}>
                <img src="/images/8%20SCENE.svg" alt="mathclub"/>
            </Grid>
        </StyledGrid>
    );
};
