import React, {Component} from 'react';

import { styled } from '@mui/material/styles';

import PropTypes from 'prop-types';

import CircularProgress from '@mui/material/CircularProgress';

const PREFIX = 'LaunchScreen';

const classes = {
  circularProgress: `${PREFIX}-circularProgress`
};

const StyledCircularProgress = styled(CircularProgress)((
  {
    theme
  }
) => ({
  [`&.${classes.circularProgress}`]: {
    position: 'absolute',
    top: '50%',
    left: '50%'
  }
}));

class LaunchScreen extends Component {
  render() {
    return <StyledCircularProgress className={classes.circularProgress} />;
  }
}

LaunchScreen.propTypes = {
  classes: PropTypes.object.isRequired
};

export default (LaunchScreen);