import React from "react";
import {CircularProgress, Grid} from "@mui/material";

export default class MyInfiniteScroll extends React.Component {

    sentinel = null;

    onSentinelIntersection = entries => {
        const {loadMore} = this.props;

        entries.forEach(entry => {
            if (entry.isIntersecting) {
                loadMore();
            }
        });
    };

    componentDidMount() {
        this.observer = new IntersectionObserver(this.onSentinelIntersection);
        this.observer.observe(this.sentinel);
    }

    render() {
        const {children, loading} = this.props;
        return (
            <React.Fragment>
                {children}
                {loading && <Grid key={0} container justifyContent="center" direction="row" style={{marginTop: "12px"}}>
                    <Grid item><CircularProgress/></Grid>
                </Grid>}
                <div ref={c => (this.sentinel = c)}/>
            </React.Fragment>
        );
    }
}
