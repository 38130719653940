import {EditorState} from "draft-js";
import * as AtomicBlockUtils from "draft-js/lib/AtomicBlockUtils";


export const insertContent = (editorState, imgUrl) => {

    // create an entity
    const contentState = editorState.getCurrentContent();

    // // set the url of the entity to the usrl chosen for the image
    const contentStateWithEntity = contentState.createEntity(
        'image',
        'IMMUTABLE',
        {
            src: imgUrl
        },
    );

    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(
        editorState,
        {currentContent: contentStateWithEntity}
    );

    // insert a new atomic block with the entity and a whit space as the text
    return AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, ' ');
};

// Custom overrides for "code" style.
export const styleMap = {
    CODE: {
        fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
        fontSize: 16,
        padding: 2,
    },
};

export function getBlockStyle(block) {
    switch (block.getType()) {
        case 'blockquote':
            return 'RichEditor-blockquote';
        default:
            return null;
    }
}
