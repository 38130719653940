import React, {Component} from 'react';

import { styled } from '@mui/material/styles';

import PropTypes from 'prop-types';

import firebase from 'firebase/app';

import Hidden from '@mui/material/Hidden';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

import FacebookBoxIcon from '@mui/icons-material/Facebook'
import GoogleIcon from '@mui/icons-material/Google';
import TwitterIcon from '@mui/icons-material/Twitter';

const PREFIX = 'AuthProviderList';

const classes = {
    dialogActions: `${PREFIX}-dialogActions`,
    dialogActionsMobile: `${PREFIX}-dialogActionsMobile`,
    buttonMobile: `${PREFIX}-buttonMobile`,
    facebook: `${PREFIX}-facebook`,
    google: `${PREFIX}-google`,
    gitHub: `${PREFIX}-gitHub`,
    twitter: `${PREFIX}-twitter`,
    icon: `${PREFIX}-icon`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
    {
        theme
    }
) => ({
    [`& .${classes.dialogActions}`]: {
        justifyContent: 'center',

        marginTop: theme.spacing(2.5),
        marginBottom: theme.spacing(1)
    },

    [`& .${classes.dialogActionsMobile}`]: {
        display: 'grid',
        justifyContent: 'stretch',

        marginTop: theme.spacing(2.5),
        marginBottom: -theme.spacing(0.5)
    },

    [`& .${classes.buttonMobile}`]: {
        marginBottom: theme.spacing(1.5)
    },

    [`& .${classes.facebook}`]: {
        backgroundColor: '#3c5a99',
        color: '#ffffff'
    },

    [`& .${classes.google}`]: {
        backgroundColor: '#4285f4',
        color: '#ffffff'
    },

    [`& .${classes.gitHub}`]: {
        backgroundColor: '#24292e',
        color: '#ffffff'
    },

    [`& .${classes.twitter}`]: {
        backgroundColor: '#1da1f2',
        color: '#ffffff'
    },

    [`& .${classes.icon}`]: {
        marginRight: theme.spacing(0.5)
    }
}));

class AuthProviderList extends Component {
    render() {

        // Properties
        const {isPerformingAuthAction} = this.props;

        // Events
        const {onAuthProviderClick} = this.props;

        return (
            <Root>
                <Hidden only="xs">
                    <DialogActions className={classes.dialogActions}>
                        <Button className={classes.facebook} disabled={isPerformingAuthAction} variant="contained"
                                onClick={() => onAuthProviderClick(new firebase.auth.FacebookAuthProvider())}>
                            <FacebookBoxIcon className={classes.icon}/>
                            Facebook
                        </Button>

                        <Button className={classes.google} disabled={isPerformingAuthAction} variant="contained"
                                onClick={() => onAuthProviderClick(new firebase.auth.GoogleAuthProvider())}>
                            <GoogleIcon className={classes.icon}/>
                            Google
                        </Button>

                        <Button className={classes.twitter} disabled={isPerformingAuthAction} variant="contained"
                                onClick={() => onAuthProviderClick(new firebase.auth.TwitterAuthProvider())}>
                            <TwitterIcon className={classes.icon}/>
                            Twitter
                        </Button>
                    </DialogActions>
                </Hidden>

                <Hidden only={['sm', 'md', 'lg', 'xl']}>
                    <DialogActions className={classes.dialogActionsMobile} disableSpacing>
                        <Button className={`${classes.facebook} ${classes.buttonMobile}`}
                                disabled={isPerformingAuthAction} variant="contained"
                                onClick={() => onAuthProviderClick(new firebase.auth.FacebookAuthProvider())}>
                            <FacebookBoxIcon className={classes.icon}/>
                            Facebook
                        </Button>

                        <Button className={`${classes.google} ${classes.buttonMobile}`}
                                disabled={isPerformingAuthAction} variant="contained"
                                onClick={() => onAuthProviderClick(new firebase.auth.GoogleAuthProvider())}>
                            <GoogleIcon className={classes.icon}/>
                            Google
                        </Button>

                        <Button className={`${classes.twitter} ${classes.buttonMobile}`}
                                disabled={isPerformingAuthAction} variant="contained"
                                onClick={() => onAuthProviderClick(new firebase.auth.TwitterAuthProvider())}>
                            <TwitterIcon className={classes.icon}/>
                            Twitter
                        </Button>
                    </DialogActions>
                </Hidden>
            </Root>
        );
    }
}

AuthProviderList.propTypes = {
    classes: PropTypes.object.isRequired,

    isPerformingAuthAction: PropTypes.bool,

    onAuthProviderClick: PropTypes.func.isRequired
};

export default (AuthProviderList);