import React, {useEffect, useState} from "react";
import {styled} from '@mui/material/styles';
import {Paper, Tab, Tabs} from "@mui/material";
import {StyledBadge} from "./StyledBadge";
import TabPanel from "../../components/TabPanel";
import MyInfiniteScroll from "../../components/MyInfiniteScroll";
import {
    fetchUserAskedExercises,
    fetchUserFollowedExercises,
    fetchUserRespondedExercises,
    NO_MORE
} from "../../service/exercise.service";
import _ from "lodash";
import {Exercise} from "../ExerciseListContent/Exercise";

const PREFIX = 'UserActivity';

const classes = {
    tabPadding: `${PREFIX}-tabPadding`
};

const StyledPaper = styled(Paper)((
    {
        theme
    }
) => ({
    [`& .${classes.tabPadding}`]: {
        padding: theme.spacing(1),
    }
}));

const tabValue = {
    intrebari: 'intrebari',
    raspunsuri: 'raspunsuri',
    urmarite: 'urmarite'
}

function exerciseMapping(user, onMessage) {
    return exId => <Exercise key={exId} exId={exId} onMessage={onMessage} user={user}
                             isNew={false} showDetails={false} variant="outlined"/>;
}

export const UserActivity = ({user, onMessage}) => {
    const [tab, setTab] = useState('intrebari');
    const [loading, setLoading] = useState(false);
    const [nextPageFollowed, setNextPageFollowed] = useState(null);
    const [nextPageAsked, setNextPageAsked] = useState(null);
    const [followedIds, setFollowedIds] = useState([]);
    const [askIds, setAskIds] = useState([]);
    const [respIds, setRespIds] = useState([]);
    const [nextPageResp, setNextPageResp] = useState(null);
    const {uid} = user;

    useEffect(() => {
        fetchUserAskedExercises(uid, nextPageAsked).then(handleExerciseResp());
        // eslint-disable-next-line
    }, []);

    const questionsCount = _.get(user, 'questionsCount', 0);
    const answersCount = _.get(user, 'answersCount', 0);
    const followedCount = _.get(user, 'followedCount', 0);

    function handleExerciseResp(followByExercises) {
        return resp => {
            let docs = _.get(resp, 'docs', []);
            let fetchedExerciseIds = docs.map((doc) => doc.id);

            let length = docs.length;
            let nextPage = length > 0 ? docs[length - 1] : null;

            if (followByExercises) {
                setNextPageFollowed(nextPage ? nextPage : NO_MORE);
                setFollowedIds([...followedIds, ...fetchedExerciseIds]);
            } else {
                setNextPageAsked(nextPage ? nextPage : NO_MORE);
                setAskIds([...askIds, ...fetchedExerciseIds])
            }

            setTimeout(() => setLoading(false), 700);
        };
    }

    function handleSolutionResp() {
        return resp => {
            let docs = _.get(resp, 'docs', []);
            let length = docs.length;
            let nextPageResp = length > 0 ? docs[length - 1] : null;
            if (!nextPageResp) {
                setNextPageResp(NO_MORE);
            } else setNextPageResp(nextPageResp);

            const fetchedExerciseIds = docs.map(doc => doc.ref.path.split('/')[1]); //extract exId from path
            setRespIds([...respIds, ...fetchedExerciseIds]);
            setTimeout(() => setLoading(false), 700);
        }
    }

    const askExercises = askIds.map(exerciseMapping(user, onMessage))
    const respExercises = respIds.map(exerciseMapping(user, onMessage));
    const followedExercises = followedIds.map(exerciseMapping(user, onMessage));

    return (
        <StyledPaper>
            <Tabs
                value={tab}
                indicatorColor="primary"
                textColor="primary"
                onChange={(e, tab) => setTab(tab)}
                aria-label="intrebari - raspunsuri - urmarite">
                <Tab disabled={!questionsCount} label={
                    <StyledBadge badgeContent={questionsCount} color="secondary" max={1000}>
                        Intrebari &nbsp;&nbsp;
                    </StyledBadge>} value={tabValue.intrebari}/>
                <Tab disabled={!answersCount} label={
                    <StyledBadge badgeContent={answersCount} color="secondary" max={1000}>
                        Raspunsuri &nbsp;&nbsp;
                    </StyledBadge>} value={tabValue.raspunsuri}/>
                <Tab disabled={!followedCount} label={
                    <StyledBadge badgeContent={followedCount} color="secondary" max={1000}>
                        Urmarite &nbsp;&nbsp;
                    </StyledBadge>} value={tabValue.urmarite}/>
            </Tabs>
            <TabPanel
                className={classes.tabPadding}
                value={tab}
                index={tabValue.intrebari}>
                <MyInfiniteScroll
                    loading={loading}
                    loadMore={() => {
                        if (uid) {
                            setLoading(true);
                            fetchUserAskedExercises(uid, nextPageAsked).then(handleExerciseResp());
                        }
                    }}>
                    {askExercises}
                </MyInfiniteScroll>
            </TabPanel>
            <TabPanel
                className={classes.tabPadding}
                value={tab} index={tabValue.raspunsuri}>
                <MyInfiniteScroll
                    loading={loading}
                    loadMore={() => {
                        if (uid) {
                            setLoading(true);
                            fetchUserRespondedExercises(uid, nextPageResp).then(handleSolutionResp());
                        }
                    }}>
                    {respExercises}
                </MyInfiniteScroll>
            </TabPanel>
            <TabPanel className={classes.tabPadding}
                      value={tab} index={tabValue.urmarite}>
                <MyInfiniteScroll
                    loading={loading}
                    loadMore={() => {
                        if (uid) {
                            setLoading(true);
                            fetchUserFollowedExercises(uid, nextPageFollowed).then(handleExerciseResp(true));
                        }
                    }}>
                    {followedExercises}
                </MyInfiniteScroll>
            </TabPanel>
        </StyledPaper>
    );
}
