import {analytics} from "../App/App";

export const content = {
    searchExercise: 'search_exercise',
    exerciseAdded: 'exercise_added',
    error: 'error',
    removeFollowed: 'removed_followed',
    addFollow: 'add_follow',
    explore: 'explore',
    viewExercise: 'view_exercise',
    viewProfile: 'view_profile'
}

export const track = (content, params) => {
    analytics.logEvent(content, params)
}