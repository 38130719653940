import {createTheme} from "@mui/material/styles";
import {green, orange, red} from "@mui/material/colors";
import grey from "@mui/material/colors/grey";

export const redGreenTheme = createTheme({
    palette: {
        primary: {main: green[400]},
        secondary: {main: red[800]},
    },
});

export const greyYellowTheme = createTheme({
    palette: {
        primary: {main: grey[400]},
        secondary: {main: orange[400]},
    }
})
