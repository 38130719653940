import React, {useContext} from "react";
import {styled} from '@mui/material/styles';
import FileUploader from "react-firebase-file-uploader";
import firebase from 'firebase/app';
import 'firebase/firebase-firestore';
import {insertContent} from "../utils/EditorUtils";
import {ExerciseContext} from "../content/ExerciseListContent/Exercise";
import Button from "@mui/material/Button";
import AttachFileIcon from "@mui/icons-material/AttachFile";

const PREFIX = 'FileUploadAction';

const classes = {
    uploadInput: `${PREFIX}-uploadInput`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
    {
        theme
    }
) => ({
    [`& .${classes.uploadInput}`]: {
        display: 'none'
    }
}));

export {};


export const FileUploadAction = ({editorState, setEditorState}) => {

    const {onMessage} = useContext(ExerciseContext);

    return (
        <Root>
            <FileUploader
                id="upload-button-file"
                className={classes.uploadInput}
                accept="image/*"
                name="exercisePic"
                randomizeFilename
                storageRef={firebase.storage().ref("exercises")}
                onUploadStart={() => console.log('upload started')}
                onUploadError={(e) => onMessage('Trebuie sa va autentificati pentru a incarca poze')}
                onUploadSuccess={(filename) => {
                    console.log('success uploading');
                    firebase
                        .storage()
                        .ref("exercises")
                        .child(filename)
                        .getDownloadURL()
                        .then(url => {
                            console.log('download url : ' + url);
                            setEditorState(insertContent(editorState, url))
                        });
                }}/>
            <label htmlFor="upload-button-file">
                <Button color="primary" aria-label="upload picture" component="span"
                        startIcon={<AttachFileIcon/>}>Incarca poza</Button>
            </label>
        </Root>
    );
}