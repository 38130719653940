import React from 'react';
import { styled } from '@mui/material/styles';
import TextField from "@mui/material/TextField";
import {MathFieldComponent} from "react-mathlive";
import Grid from "@mui/material/Grid";
import {Show} from "../../utils/common";
import IconButton from "@mui/material/IconButton";
import {Delete} from "@mui/icons-material";

const PREFIX = 'SimpleEditorComponent';

const classes = {
    equation: `${PREFIX}-equation`,
    fullWidth: `${PREFIX}-fullWidth`
};

const StyledGrid = styled(Grid)((
    {
        theme
    }
) => ({
    [`&.${classes.equation}`]: {
        background: theme.palette.mode === 'dark' ? '#5a5a5a' : '#e7e6e6',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        borderRadius: 5,
    },

    [`& .${classes.fullWidth}`]: {
        flexGrow: 1
    }
}));


export const SimpleEditorComponent = ({state, index, onChange, onFocus, focusedIndex, onRemove}) => {
    if (state.type === 'text') {
        return <TextField className="simple-editor" multiline fullWidth value={state.value}
                          onFocus={() => onFocus(index)}
                          onChange={e => {
                              onChange(e.target.value, index);
                          }}/>;
    } else if (state.type === 'equation') {
        return (
            <StyledGrid container className={classes.equation} direction="row">
                <Show value={index === focusedIndex}>
                    <IconButton onClick={() => onRemove(index)} size="large"><Delete/></IconButton>
                </Show>
                <Grid item xs={11}>
                    <MathFieldComponent latex={state.value}
                                        onChange={(value) => {
                                            onChange(value, index);
                                        }}
                                        mathFieldConfig={{
                                            defaultMode: "text",
                                            virtualKeyboardMode: "onfocus",
                                            smartMode: true,
                                            onFocus: () => {
                                                onFocus(index);
                                            },
                                        }}/>
                </Grid>
            </StyledGrid>
        );
    }
}