/**
 * ROUTE constans
 */
import {uuid} from "./utils/common";

export const SEARCH_EXERCISE_PREFIX = "/search/";
export const SEARCH_EXERCISE_PATTERN = "/search/:searchValue";
export const VIEW_PROFILE_PATTERN = "/profile/:userId";
export const VIEW_PROFILE_PREFIX = "/profile/";
export const EXPLORE_EXERCISE = "/explore";
export const EXERCISE_DETAILS_PATTERN = "/exercise/:exerciseId";
export const EXERCISE_DETAILS_PREFIX = "/exercise/";
export const ADD_EXERCISE = "/addExercise";
export const HOME = "/";

/**
 * Editor constants
 */
export const EMPTY_EQUATION = {type: 'equation', value: '', uuid: uuid()};
export const EMPTY_TEXT = {type: 'text', value: '', uuid: uuid()};
