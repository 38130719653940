import React, {Component} from "react";
import {connectInfiniteHits} from 'react-instantsearch-dom';
import PropTypes from 'prop-types';
import {Hit} from "./Hit";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import {Show} from "../utils/common";
import _ from 'lodash';
import Fab from "@mui/material/Fab";
import {Link} from 'react-router-dom';
import {ADD_EXERCISE} from "../constants";
import AddIcon from "@mui/icons-material/Add";
import EmptyState from "../layout/EmptyState/EmptyState";
import FindIcon from "@mui/icons-material/FindInPage";

class InfiniteHits extends Component {
    static propTypes = {
        hits: PropTypes.arrayOf(PropTypes.object).isRequired,
        hasMore: PropTypes.bool.isRequired,
        refine: PropTypes.func.isRequired,
    };

    sentinel = null;

    onSentinelIntersection = entries => {
        const {hasMore, refineNext} = this.props;

        entries.forEach(entry => {
            if (entry.isIntersecting && hasMore) {
                refineNext();
            }
        });
    };

    componentDidMount() {
        this.observer = new IntersectionObserver(this.onSentinelIntersection);
        this.observer.observe(this.sentinel);
    }

    componentWillUnmount() {
        this.observer.disconnect();
    }

    render() {
        const {hits, hasMore, onMessage, onSignInClick} = this.props;
        const uniqueHits = _.uniqWith(hits, (a, b) => a.id === b.id);

        return (
            <React.Fragment>
                <Grid container justifyContent="center">
                    {
                        uniqueHits.map(hit => (
                            <Grid item xs={12} md={8}>
                                <Hit key={hit.objectID} hit={hit} onMessage={onMessage} onSignInClick={onSignInClick}/>
                            </Grid>
                        ))
                    }
                </Grid>
                <Show value={uniqueHits.length === 0}>
                    <EmptyState
                        icon={<FindIcon style={{fontSize: 80}} color="action"/>}
                        title="Am cautat peste tot"
                        description="si nu am gasit. Adauga intrebarea sa vedem daca gasim pe cineva sa raspunda"
                        button={
                            <Fab style={{marginTop: 12}} color="primary" component={Link} to={ADD_EXERCISE}
                                 variant="extended">
                                <AddIcon style={{marginRight: 5}}/> Adauga o intrebare
                            </Fab>
                        }
                    />
                </Show>
                <Show value={hasMore}>
                    <Grid container alignItems="center">
                        <Grid style={{textAlign: 'center'}} item xs={12}>
                            <CircularProgress/>
                        </Grid>
                    </Grid>
                </Show>
                <div ref={c => (this.sentinel = c)}/>
            </React.Fragment>
        );
    }
}

export default connectInfiniteHits(InfiniteHits);
