import React from 'react';
import 'firebase/firebase-firestore';
import InfiniteHits from "../../algolia/InfiniteHits";

const ExerciseListContent = ({onMessage, user, onSignInClick}) =>
    (
        <React.Fragment>
            <InfiniteHits onMessage={onMessage} user={user} onSignInClick={onSignInClick}/>
        </React.Fragment>
    );


export default ExerciseListContent;
