import React from 'react';
import {styled} from '@mui/material/styles';
import {Divider, Grid} from "@mui/material";
import {Banner} from "./Banner";
import {HowItWorks} from "./HowItWorks";
import Footer from "../../components/Footer";
import CookieConsent from "react-cookie-consent";
import {ShowExample} from "./ShowExample";
import {HelpWanted} from "./HelpWanted";

const PREFIX = 'HomeContent';

const classes = {
    gridPadding: `${PREFIX}-gridPadding`,
    gridTopMargin: `${PREFIX}-gridTopMargin`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
    {
        theme
    }
) => ({
    [`& .${classes.gridPadding}`]: {
        padding: theme.spacing(2),
    },
    [`& .${classes.gridTopMargin}`]: {
        marginTop: theme.spacing(3),
    },
}));

export default function HomeContent() {


    return (
        <Root>
            <Grid container className={classes.gridPadding}>
                <Grid item xs={12}>
                    <Banner/>
                </Grid>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>
                <Grid item xs={12}>
                    <HowItWorks/>
                </Grid>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>
                <Grid item xs={12} className={classes.gridTopMargin}>
                    <ShowExample/>
                </Grid>
                <Grid item xs={12} className={classes.gridTopMargin}>
                    <Divider/>
                </Grid>
                <Grid item xs={12} className={classes.gridTopMargin}>
                    <HelpWanted/>
                </Grid>
                <Grid item xs={12} className={classes.gridTopMargin}>
                    <Divider/>
                </Grid>
            </Grid>
            <Footer/>
            <CookieConsent buttonText="Inteleg">Acest site foloseste cookies pentru a imbunatati
                experienta</CookieConsent>
        </Root>
    );
}
