import red from '@mui/material/colors/red';
import pink from '@mui/material/colors/pink';
import purple from '@mui/material/colors/purple';
import deepPurple from '@mui/material/colors/deepPurple';
import indigo from '@mui/material/colors/indigo';
import blue from '@mui/material/colors/blue';
import lightBlue from '@mui/material/colors/lightBlue';
import cyan from '@mui/material/colors/cyan';
import teal from '@mui/material/colors/teal';
import green from '@mui/material/colors/green';
import lightGreen from '@mui/material/colors/lightGreen';
import lime from '@mui/material/colors/lime';
import yellow from '@mui/material/colors/yellow';
import amber from '@mui/material/colors/amber';
import orange from '@mui/material/colors/orange';
import deepOrange from '@mui/material/colors/deepOrange';
import brown from '@mui/material/colors/brown';
import gray from '@mui/material/colors/grey';
import blueGray from '@mui/material/colors/blueGrey';

const colors = [
    {
        id: 'red',
        name: 'Red',
        import: red[400]
    },
    {
        id: 'pink',
        name: 'Pink',
        import: pink[400]
    },
    {
        id: 'purple',
        name: 'Purple',
        import: purple[400]
    },
    {
        id: 'deep-purple',
        name: 'Deep Purple',
        import: deepPurple[400]
    },
    {
        id: 'indigo',
        name: 'Indigo',
        import: indigo[400]
    },
    {
        id: 'blue',
        name: 'Blue',
        import: blue[400]
    },
    {
        id: 'light-blue',
        name: 'Light Blue',
        import: lightBlue[400]
    },
    {
        id: 'cyan',
        name: 'Cyan',
        import: cyan[400]
    },
    {
        id: 'teal',
        name: 'Teal',
        import: teal[400]
    },
    {
        id: 'green',
        name: 'Green',
        import: green[400]
    },
    {
        id: 'light-green',
        name: 'Light Green',
        import: lightGreen[400]
    },
    {
        id: 'lime',
        name: 'Lime',
        import: lime[400]
    },
    {
        id: 'yellow',
        name: 'Yellow',
        import: yellow[400]
    },
    {
        id: 'amber',
        name: 'Amber',
        import: amber[400]
    },
    {
        id: 'orange',
        name: 'Orange',
        import: orange[400]
    },
    {
        id: 'deep-orange',
        name: 'Deep Orange',
        import: deepOrange[400]
    },
    {
        id: 'brown',
        name: 'Brown',
        import: brown[400]
    },
    {
        id: 'gray',
        name: 'Gray',
        import: gray[400]
    },
    {
        id: 'blue-gray',
        name: 'Blue Gray',
        import: blueGray[400]
    }
];

export default colors;