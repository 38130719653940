import React, {useEffect, useState} from "react";
import {styled} from '@mui/material/styles';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import firebase from 'firebase/app';
import 'firebase/firebase-firestore';
import {TexEditor} from "../../components/TexEditor";
import CardHeader from "@mui/material/CardHeader";
import Avatar from "@mui/material/Avatar";
import PersonIcon from "@mui/icons-material/Person";
import Button from "@mui/material/Button";
import {EXERCISE_DETAILS_PREFIX, VIEW_PROFILE_PREFIX} from "../../constants";
import {useHistory} from "react-router-dom";
import Solutions from "../../components/Solutions";
import {
    extractRequirementTitle,
    getEditorStateFromString,
    humanizeDate,
    isEmptyDraftJs,
    isSameUserOrAdmin,
    Show,
    uuid
} from "../../utils/common";
import {convertToRaw, EditorState} from "draft-js";
import {ExerciseActions} from "./ExerciseActions";
import _ from 'lodash';
import {fetchExercise, incrementViewCount} from "../../service/exercise.service";
import {ConditionalCardActionArea} from "../../components/ConditionalCardActionArea";
import {ExerciseSubheader} from "./ExerciseSubheader";
import {content, track} from "../../utils/analytics";
import SimilarExercises from "./SimilarExercises";

const PREFIX = 'ExerciseContext';

const classes = {
    container: `${PREFIX}-container`,
    exercise: `${PREFIX}-exercise`,
    exPadding: `${PREFIX}-exPadding`,
    button: `${PREFIX}-button`,
    postNewQuestion: `${PREFIX}-postNewQuestion`
};

const StyledExercise = styled('div')((
    {
        theme
    }
) => ({
    [`& .${classes.exercise}`]: {
        [theme.breakpoints.up('md')]: {
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
        },
        [theme.breakpoints.down('xl')]: {
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
        },
    },

    [`& .${classes.exPadding}`]: {
        [theme.breakpoints.up('md')]: {
            paddingLeft: theme.spacing(5),
            paddingRight: theme.spacing(5)
        },
        [theme.breakpoints.down('md')]: {
            paddingLeft: 0,
            paddingRight: 0,
        }
    },

    [`& .${classes.button}`]: {
        margin: theme.spacing(1),
    },

    [`& .${classes.postNewQuestion}`]: {
        paddingTop: theme.spacing(1)
    }
}));

export const ExerciseContext = React.createContext({});

export const Exercise = ({exId, onMessage, showDetails, user, isNew, variant, onSignInClick}) => {

    const history = useHistory();

    let [ex, setEx] = useState(null);
    let {userDisplayName, userPhotoUrl, userUUID} = extractUserDetails(ex, user);

    //region state management
    const [tags, setTags] = useState([]);
    const [tagsError, setTagsError] = useState(false);
    const [requirementEditorState, setRequirementEditorState] = useState(null);
    const [advancedEditor, setAdvancedEditor] = useState(true);
    const [simpleEditorState, setSimpleEditorState] = useState([{
        type: "text",
        value: "",
        uuid: uuid(),
    }]);

    useEffect(() => {
        if (exId) {
            if (showDetails) {
                incrementViewCount(exId)
                    .then(() => fetchExercise(exId, ex, setEx, onMessage))
                    .catch(err => console.log(err));
            } else {
                fetchExercise(exId, ex, setEx, onMessage);
            }
        } else setRequirementEditorState(EditorState.createEmpty());
        // eslint-disable-next-line
    }, [exId])

    useEffect(() => {
        if (ex) {
            setTags(ex.tags ? ex.tags.split(',') : null);
            if (showDetails) {
                document.title = extractRequirementTitle(ex.requirement);
            }
            setRequirementEditorState(getEditorStateFromString(ex.requirement));
        }
    }, [ex, showDetails]);
    //endregion

    if (ex == null && !isNew) return null;
    const sameUserOrAdmin = isSameUserOrAdmin(user, ex);
    const createdDuration = ex ? humanizeDate(ex.created) : null;
    const readOnly = (!showDetails && !isNew) || (showDetails && !sameUserOrAdmin);

    return (
        <ExerciseContext.Provider value={{
            user: user,
            ex: ex,
            sameUserOrAdmin: sameUserOrAdmin,
            isNewExercise: isNew,
            showDetails: showDetails,
            onMessage: onMessage,
            requirementEditorState: requirementEditorState,
            onExerciseChanged: () => fetchExercise(exId, ex, setEx, onMessage),
            onSignInClick: onSignInClick,
            tags: tags,
            setTags: setTags,
            setRequirementEditorState: setRequirementEditorState,
            advancedEditor: advancedEditor,
            setAdvancedEditor: setAdvancedEditor,
            simpleEditorState,
            setSimpleEditorState,
            tagsError,
            setTagsError,
        }}>
            <StyledExercise>
                <Grid container className={showDetails ? classes.container : null}>
                    <Grid item className={`${classes.exercise} ${showDetails ? classes.exPadding : null}`} xs={12}>
                        <Card variant={variant}>
                            <ConditionalCardActionArea
                                enabled={!showDetails && !isNew}
                                onClick={() => (!showDetails && exId) && history.push(EXERCISE_DETAILS_PREFIX + exId)}>
                                <ConditionalCardActionArea
                                    enabled={showDetails}
                                    onClick={() => history.push(VIEW_PROFILE_PREFIX + userUUID)}>
                                    <CardHeader
                                        avatar={(userPhotoUrl) ? <Avatar alt="Avatar" src={userPhotoUrl}/> :
                                            <Avatar><PersonIcon/></Avatar>}
                                        title={userDisplayName}
                                        subheader={<ExerciseSubheader createdDuration={createdDuration}
                                                                      isNew={isNew}
                                                                      reads={ex && ex.reads}
                                                                      solutions={ex && ex.solutions && ex.solutions.length}/>}/>
                                </ConditionalCardActionArea>
                                <ExerciseActions/>
                                <CardContent>
                                    <TexEditor editorState={requirementEditorState}
                                               placeholder="foloseste $ pentru a introduce o ecuatie"
                                               simpleEditorState={simpleEditorState}
                                               advanced={advancedEditor}
                                               readOnly={readOnly}
                                               isNew={isNew}
                                               onChange={setRequirementEditorState}
                                               onSimpleEditorChange={(value, index) => {
                                                   simpleEditorState[index].value = value;
                                                   setSimpleEditorState(_.clone(simpleEditorState));
                                               }}/>
                                    <Show value={showDetails}>
                                        <Solutions ex={ex} onMessage={onMessage} user={user}
                                                   onSignInClick={onSignInClick}
                                                   onChanged={() => fetchExercise(exId, ex, setEx, onMessage)}/>
                                    </Show>
                                    <Show value={isNew}>
                                        <Grid container justifyContent="flex-end" className={classes.postNewQuestion}>
                                            <Grid item>
                                                <Button className={classes.button} variant="contained" color="primary"
                                                        onClick={saveExercise(user, onMessage, requirementEditorState, tags, history, setTagsError, onSignInClick)}>
                                                    Posteaza intrebarea</Button>
                                            </Grid>
                                        </Grid>
                                    </Show>
                                </CardContent>
                            </ConditionalCardActionArea>
                        </Card>
                    </Grid>
                    <Show value={showDetails}>
                        <Grid item xs={12} className={`${classes.exercise} ${classes.exPadding}`}>
                            <SimilarExercises tags={ex && ex.tags} exId={ex && ex.id}/>
                        </Grid>
                    </Show>
                </Grid>
            </StyledExercise>
        </ExerciseContext.Provider>
    );

}

function saveExercise(user, onMessage, editorState, tags, history, setTagsError, onSignInClick) {
    return () => {
        if (!user) {
            onSignInClick();
            onMessage("Va rugam sa va autentificati mai intai");
            return;
        }

        if (!tags || tags.length === 0) {
            onMessage("Alege cel putin un tag");
            setTagsError(true);
            return;
        }

        if (isEmptyDraftJs(convertToRaw(editorState.getCurrentContent()))) {
            onMessage('Trebuie sa completati intrebarea');
            return;
        }

        firebase.firestore().collection("exercise").add({
            requirement: JSON.stringify(convertToRaw(editorState.getCurrentContent())),
            solution: JSON.stringify(convertToRaw(EditorState.createEmpty().getCurrentContent())),
            created: firebase.firestore.FieldValue.serverTimestamp(),
            userUUID: user.uid,
            userDisplayName: user.displayName,
            userPhotoUrl: user.photoURL,
            tags: tags && tags.toString(),
            hasSolution: false
        }).then((docRef) => {
            onMessage("Intrebarea a fost adaugata")
            history.push(EXERCISE_DETAILS_PREFIX + docRef.id);
            track(content.exerciseAdded, {userId: user.uid, userDisplayName: user.displayName, tags: tags.toString()})
        })
            .catch(resp => {
                console.log('error while saving exercise ' + resp);
                track(content.error, resp)
            });
    }
}

function extractUserDetails(ex, user) {
    let userDisplayName, userPhotoUrl, userUUID;
    if (ex) {
        userDisplayName = ex.userDisplayName;
        userPhotoUrl = ex.userPhotoUrl;
        userUUID = ex.userUUID;
    } else {
        if (user) {
            userDisplayName = user.displayName;
            userPhotoUrl = user.photoURL;
            userUUID = user.uid;
        }
    }
    return {userDisplayName, userPhotoUrl, userUUID};
}
