import React, {useEffect, useState} from "react";
import {getEditorStateFromString} from "../../utils/common";
import {Card, CardContent} from "@mui/material";
import {ConditionalCardActionArea} from "../../components/ConditionalCardActionArea";
import {useHistory} from "react-router-dom";
import {EXERCISE_DETAILS_PREFIX} from "../../constants";
import {fetchExercisesSummaryById} from "../../service/exercise.service";
import {EditorState} from "draft-js";
import {TexEditor} from "../../components/TexEditor";

export default function PreviewExercise({exId}) {
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const history = useHistory();

    useEffect(() => {
        fetchExercisesSummaryById(exId).then(ex => {
            setEditorState(getEditorStateFromString(ex.requirement));
        })
    }, [exId])

    if (!exId) return null;

    return (
        <Card>
            <ConditionalCardActionArea enabled={true} onClick={() => history.push(EXERCISE_DETAILS_PREFIX + exId)}>
                <CardContent sx={{paddingLeft: (theme) => theme.spacing(4)}}>
                    <TexEditor editorState={editorState} isNew={false} advanced={true}
                               readOnly={true} onChange={setEditorState}/>
                </CardContent>
            </ConditionalCardActionArea>
        </Card>
    )
}