import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import UpIcon from "@mui/icons-material/ArrowDropUp";
import Typography from "@mui/material/Typography";
import React, {useContext} from "react";
import CheckIcon from '@mui/icons-material/Check';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {green} from "@mui/material/colors";
import Tooltip from "@mui/material/Tooltip";
import {ExerciseContext} from "./Exercise";

import firebase from 'firebase/app';
import 'firebase/firebase-firestore';
import {extractUser} from "../../utils/common";


const checkTheme = createTheme({
    palette: {
        secondary: {
            main: green[500],
        },
    },
});


export default function Vote({number, isSolution, solution, alreadyVoted}) {
    const {user, ex, onExerciseChanged, onMessage} = useContext(ExerciseContext);

    const vote = (up) => {
        firebase.firestore().collection('exercise').doc(ex.id).collection('solutions').doc(solution.id)
            .collection('votes').add({...extractUser(user), count: up ? 1 : -1})
            .then(() => {
                console.log('vote successful');
                onExerciseChanged(true);
            })
            .catch(err => onMessage(err.message));
    }

    return (
        <Grid item container xs={1} direction="column" alignItems="center" justifyContent="center">
            <Grid item>
                <Tooltip title="Solutia este utila">
                    <IconButton onClick={() => vote(true)} disabled={alreadyVoted} size="large">
                        <UpIcon fontSize="large"/>
                    </IconButton>
                </Tooltip>
            </Grid>
            <Grid item>
                <Tooltip title="Numarul de voturi">
                    <Typography variant="h6">
                        {number}
                    </Typography>
                </Tooltip>
            </Grid>
            {/*<Grid item>*/}
            {/*    <Tooltip title="Solutia nu este utila">*/}
            {/*        <IconButton onClick={() => vote(false)}>*/}
            {/*            <DownIcon fontSize="large"/>*/}
            {/*        </IconButton>*/}
            {/*    </Tooltip>*/}
            {/*</Grid>*/}
            {isSolution &&
            <ThemeProvider theme={checkTheme}>
                <Grid item>
                    <Tooltip title="Solutia aceasta a fost aleasa ca cea mai buna">
                        <CheckIcon color="secondary" aria-label="Solutia"/>
                    </Tooltip>
                </Grid>
            </ThemeProvider>
            }

        </Grid>
    );
}