import React, {Component} from 'react';

import {styled} from '@mui/material/styles';

import PropTypes from 'prop-types';


import DialogContentText from '@mui/material/DialogContentText';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

const PREFIX = 'AppearanceTab';

const classes = {
    root: `${PREFIX}-root`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
    {
        theme
    }
) => ({
    [`& .${classes.root}`]: {
        marginBottom: theme.spacing(0)
    }
}));

const types = [
    'light',
    'dark'
];

class AppearanceTab extends Component {
    render() {
        // Properties
        const {colors, primaryColor, secondaryColor, type} = this.props;

        // Events
        const {onPrimaryColorChange, onSecondaryColorChange, onTypeChange} = this.props;

        return (
            <Root>
                <DialogContentText classes={{root: classes.root}}>
                    The app's primary and secondary colors, and their variants, help create a color theme that is
                    harmonious,
                    ensures accessible text, and distinguishes UI elements and surfaces from one another.
                </DialogContentText>

                <FormControl fullWidth margin="normal">
                    <InputLabel id="primary-color-label">Primary color</InputLabel>

                    <Select id="primary-color-select" labelId="primary-color-label" onChange={onPrimaryColorChange}
                            value={primaryColor} label="Primary color">
                        {colors.map((color) => {
                            return (<MenuItem key={color.id} value={color.id}>{color.name}</MenuItem>);
                        })}
                    </Select>
                </FormControl>

                <FormControl fullWidth margin="normal">
                    <InputLabel>Secondary color</InputLabel>

                    <Select onChange={onSecondaryColorChange} value={secondaryColor} label="Secondary color">
                        {colors.map((color) => {
                            return (<MenuItem key={color.id} value={color.id}>{color.name}</MenuItem>);
                        })}
                    </Select>
                </FormControl>

                <FormControl fullWidth margin="normal">
                    <InputLabel>Type</InputLabel>

                    <Select onChange={onTypeChange} value={type} label="Type">
                        {types.map((type, index) => {
                            return (<MenuItem key={index}
                                              value={type}>{type.charAt(0).toUpperCase() + type.slice(1)}</MenuItem>);
                        })}
                    </Select>
                </FormControl>
            </Root>
        );
    }
}

AppearanceTab.propTypes = {
    classes: PropTypes.object.isRequired,

    colors: PropTypes.array.isRequired,
    primaryColor: PropTypes.string.isRequired,
    secondaryColor: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,

    onPrimaryColorChange: PropTypes.func.isRequired,
    onSecondaryColorChange: PropTypes.func.isRequired,
    onTypeChange: PropTypes.func.isRequired
};

export default (AppearanceTab);