import React from "react";
import {Dialog, DialogContent, DialogTitle, Divider, Grid, Typography} from "@mui/material";
import {createTexEditorPlugins, getEditorStateFromString} from "../../utils/common";
import Editor from 'draft-js-plugins-editor'


const lettersEditorStateString = '{"blocks":[{"key":"4tq08","text":" \\t\\t \\\\alpha     \\t\\t \\\\eta     \\t\\t \\\\nu ","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":34,"style":"CODE"}],"entityRanges":[{"offset":1,"length":2,"key":0},{"offset":15,"length":2,"key":1},{"offset":27,"length":2,"key":2}],"data":{}},{"key":"82obs","text":" \\t\\t \\\\beta      \\t\\t \\\\theta    ","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":28,"style":"CODE"}],"entityRanges":[{"offset":1,"length":2,"key":3},{"offset":15,"length":2,"key":4}],"data":{}},{"key":"4jc3o","text":" \\t\\t \\\\gamma","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":10,"style":"CODE"}],"entityRanges":[{"offset":1,"length":2,"key":5}],"data":{}},{"key":"8mns","text":" \\t\\t \\\\delta","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":10,"style":"CODE"}],"entityRanges":[{"offset":1,"length":2,"key":6}],"data":{}}],"entityMap":{"0":{"type":"INLINETEX","mutability":"IMMUTABLE","data":{"teX":"\\\\alpha","displaystyle":false}},"1":{"type":"INLINETEX","mutability":"IMMUTABLE","data":{"teX":"\\\\eta","displaystyle":false}},"2":{"type":"INLINETEX","mutability":"IMMUTABLE","data":{"teX":"\\\\nu","displaystyle":false}},"3":{"type":"INLINETEX","mutability":"IMMUTABLE","data":{"teX":"\\\\beta","displaystyle":false}},"4":{"type":"INLINETEX","mutability":"IMMUTABLE","data":{"teX":"\\\\theta","displaystyle":false}},"5":{"type":"INLINETEX","mutability":"IMMUTABLE","data":{"teX":"\\\\gamma","displaystyle":false}},"6":{"type":"INLINETEX","mutability":"IMMUTABLE","data":{"teX":"\\\\delta","displaystyle":false}}}}';
const functionsEditorStateString = '{"blocks":[{"key":"4tq08","text":" \\t\\t \\\\alpha     \\t\\t \\\\eta     \\t\\t \\\\nuuu ","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":34,"style":"CODE"}],"entityRanges":[{"offset":1,"length":2,"key":0},{"offset":15,"length":2,"key":1},{"offset":27,"length":2,"key":2}],"data":{}},{"key":"82obs","text":" \\t\\t \\\\beta      \\t\\t \\\\theta    ","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":28,"style":"CODE"}],"entityRanges":[{"offset":1,"length":2,"key":3},{"offset":15,"length":2,"key":4}],"data":{}},{"key":"4jc3o","text":" \\t\\t \\\\gamma","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":10,"style":"CODE"}],"entityRanges":[{"offset":1,"length":2,"key":5}],"data":{}},{"key":"8mns","text":" \\t\\t \\\\delta","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":10,"style":"CODE"}],"entityRanges":[{"offset":1,"length":2,"key":6}],"data":{}}],"entityMap":{"0":{"type":"INLINETEX","mutability":"IMMUTABLE","data":{"teX":"\\\\alpha","displaystyle":false}},"1":{"type":"INLINETEX","mutability":"IMMUTABLE","data":{"teX":"\\\\eta","displaystyle":false}},"2":{"type":"INLINETEX","mutability":"IMMUTABLE","data":{"teX":"\\\\nu","displaystyle":false}},"3":{"type":"INLINETEX","mutability":"IMMUTABLE","data":{"teX":"\\\\beta","displaystyle":false}},"4":{"type":"INLINETEX","mutability":"IMMUTABLE","data":{"teX":"\\\\theta","displaystyle":false}},"5":{"type":"INLINETEX","mutability":"IMMUTABLE","data":{"teX":"\\\\gamma","displaystyle":false}},"6":{"type":"INLINETEX","mutability":"IMMUTABLE","data":{"teX":"\\\\delta","displaystyle":false}}}}';

const LATEX_CHEAT_SHEET = [
    {sectionName: 'Litere', editorState: lettersEditorStateString},
    {sectionName: 'Functii comune', editorState: functionsEditorStateString},
];

const getStateFromSection = (editorState, sectionName) => {
    const res = {};
    res[sectionName] = editorState;
    return res;
}

export class LatexCheatSheetDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        LATEX_CHEAT_SHEET.forEach(sheet => {
            //eslint-disable-next-line
            this.state[sheet.sectionName] = getEditorStateFromString(sheet.editorState);
        });
    }

    render() {
        const {open, onClose} = this.props;

        return (
            <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="md">
                <DialogTitle>Simboluri LaTeX</DialogTitle>
                <DialogContent>
                    <Grid container direction="column" spacing="1">
                        {
                            LATEX_CHEAT_SHEET.map((sheet, index) => (
                                <Grid item xs key={sheet.sectionName}>
                                    <Typography variant="h6">{sheet.sectionName}</Typography>
                                    <Divider light={true}/>
                                    <Editor
                                        readOnly={true}
                                        editorState={this.state[sheet.sectionName]}
                                        onChange={editorState => this.setState(getStateFromSection(editorState, sheet.sectionName))}
                                        plugins={createTexEditorPlugins()().plugins}
                                    />
                                </Grid>
                            ))
                        }
                    </Grid>
                </DialogContent>
            </Dialog>
        );
    }
}
