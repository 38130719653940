import React from "react";
import {StyleControlButton} from "./StyleControlButton";
import {Typography} from "@mui/material";
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';

export const BLOCK_TYPES = [
    {icon: <Typography variant="button">H1</Typography>, style: 'header-two', tooltip: 'Titlu 1'},
    {icon: <Typography variant="button">H2</Typography>, style: 'header-three', tooltip: 'Titlu 2'},
    {icon: <Typography variant="button">H3</Typography>, style: 'header-four', tooltip: 'Titlu 3'},
    {icon: <FormatQuoteIcon/>, style: 'blockquote', tooltip: 'Sectiune citata'},
    {icon: <FormatListBulletedIcon/>, style: 'unordered-list-item', tooltip: 'Lista'},
    {icon: <FormatListNumberedIcon/>, style: 'ordered-list-item', tooltip: 'Lista ordonata'},
    {icon: <DeveloperModeIcon/>, style: 'code-block', tooltip: 'Bloc de cod'},
];

export const BlockStyleControls = ({editorState, onToggle}) => {
    const selection = editorState.getSelection();
    const blockType = editorState
        .getCurrentContent()
        .getBlockForKey(selection.getStartKey())
        .getType();

    return (
        <React.Fragment>
            {BLOCK_TYPES.map(type => (
                <StyleControlButton
                    key={type.style}
                    active={blockType === type.style}
                    icon={type.icon}
                    onToggle={onToggle}
                    style={type.style}
                    tooltip={type.tooltip}
                />
            ))}
        </React.Fragment>
    );
}
