import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
import {lessons} from "../../ExerciseListContent/lessonsConstants";
import {Box, ListItemText} from "@mui/material";

export default function Tags({onChange, error}) {
    // const defaultValue = ['geometrie', 'algebra', 'trigonometrie', 'clasa a V-a',
    //     'clasa a VI-a', 'clasa a VII-a', 'clasa a VIII-a', 'clasa a IX-a',
    //     'clasa a X-a', 'clasa a XI-a', 'clasa a XII-a'];

    return (
        <Autocomplete
            multiple
            sx={{width: 400}}
            id="tags-filled"
            disableClearable
            getOptionLabel={(option) => option.name}
            options={lessons}
            freeSolo
            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip variant="outlined" label={option.grade + ' - ' + option.name} {...getTagProps({index})} />
                ))
            }
            renderOption={(props, option) =>
                <Box component="li" sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props}>
                    <Chip label={"clasa a " + option.grade + "-a"}/>
                    <ListItemText
                        sx={{marginLeft: (theme) => theme.spacing(1)}}>{option.name}</ListItemText>
                </Box>
            }
            renderInput={params => (
                <TextField error={error} {...params} variant="outlined" label="Lectie" style={{minWidth: 200}}/>
            )}
            onChange={(e, value) => {
                onChange && onChange(value && value.map(v => v.grade + ' - ' + v.name))
            }}
        />
    );
}