import React from 'react'
import {styled} from '@mui/material/styles';
import {Show} from "../utils/common";
import {SimpleEditor} from "../content/ExerciseListContent/SimpleEditor";
import {AdvancedEditor} from "./AdvancedEditor";

const PREFIX = 'TexEditor';

const classes = {
    editor: `${PREFIX}-editor`,
    title: `${PREFIX}-title`,
    noBorder: `${PREFIX}-noBorder`
};

const Root = styled('div')((
    {
        theme
    }
) => ({
    [`&.${classes.editor}`]: {
        boxSizing: 'border-box',
        textAlign: 'left',
        fontFamily: '\'Open Sans\', sans-serif',
        padding: 16,
        minHeight: "40vh",
        width: "100%",
        maxWidth: "100%",
        color: theme.palette.text.primary,
        marginLeft: 'auto',
        marginRight: 'auto',
        borderRadius: '10px',
        background: theme.palette.mode === 'dark' ? '#5a5a5a' : '#f5f5f5',
        overflow: 'auto'
    },

    [`& .${classes.title}`]: {
        textAlign: 'center',
    },

    [`& .${classes.noBorder}`]: {
        border: 'none'
    }
}));

export const TexEditor = (props) => {
    const {
        placeholder,
        onChange,
        editorState,
        readOnly,
        advanced,
        simpleEditorState,
        onSimpleEditorChange,
        isNew
    } = props;
    let editorRef;

    return (
        <Root className={classes.editor} style={{minHeight: isNew ? '40vh' : 'inherit'}}
              onClick={() => editorRef && editorRef.current && editorRef.current.focus()}>
            <Show value={advanced && editorState}>
                <AdvancedEditor editorState={editorState} placeholder={placeholder} readOnly={readOnly}
                                onChange={onChange}/>
            </Show>
            <Show value={!advanced}>
                <SimpleEditor editorState={simpleEditorState}
                              onChange={onSimpleEditorChange}/>
            </Show>
        </Root>
    );
};
