import React from "react";
import {CardActionArea} from "@mui/material";

export const ConditionalCardActionArea = (props) => {
    const {enabled, onClick} = props;

    if (enabled) {
        return (
            <CardActionArea onClick={onClick}>
                {props.children}
            </CardActionArea>
        );
    } else {
        return (
            <React.Fragment>
                {props.children}
            </React.Fragment>
        );
    }

}
