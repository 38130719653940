import React, {useContext, useEffect, useState} from 'react';
import {styled, ThemeProvider} from '@mui/material/styles';
import NotificationsIcon from '@mui/icons-material/Notifications';
import IconButton from "@mui/material/IconButton";
import PersonIcon from '@mui/icons-material/Person';
import Badge from "@mui/material/Badge";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import firebase from 'firebase/app';
import 'firebase/firebase-firestore';
import {AppContext, UserContext} from "../App/App";
import {useHistory} from "react-router-dom";
import {EXERCISE_DETAILS_PREFIX} from "../constants";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import {humanizeDate} from "../utils/common";
import {ListItem, Typography} from "@mui/material";
import {redGreenTheme} from "../themes";

const PREFIX = 'UserNotifications';

const classes = {
    avatar: `${PREFIX}-avatar`
};

const StyledBox = styled(Box)((
    {
        theme
    }
) => ({
    [`& .${classes.avatar}`]: {
        marginRight: theme.spacing(1)
    }
}));

const UserNotifications = ({classes}) => {
    const [el, setEl] = useState(null);
    const [notifications, setNotifications] = useState([]);
    const [unreadCount, setUnreadCount] = useState(0);
    const user = useContext(UserContext);
    const {openSnackbar} = useContext(AppContext);


    let history = useHistory();
    // eslint-disable-next-line
    useEffect(() => fetchNotifications(user, setNotifications, openSnackbar, setUnreadCount), [user]);


    const openNotification = (e) => notifications.length > 0 && setEl(e.currentTarget);
    const closeNotification = () => setEl(null);

    return (
        <React.Fragment>
            <IconButton
                className={classes?.outlinedIcons}
                aria-label="notifications"
                onClick={openNotification}
                disabled={notifications.length === 0}
                size="small"
                sx={{px: '10px'}}>
                <ThemeProvider theme={redGreenTheme}>
                    <Badge color="secondary" badgeContent={unreadCount}>
                        <NotificationsIcon/>
                    </Badge>
                </ThemeProvider>
            </IconButton>

            {notifications.length > 0 &&
            <Menu anchorEl={el}
                  open={Boolean(el)}
                  onClose={closeNotification}>
                <ListItem button={true}>
                    <ListItemText
                        primary={<Typography variant="h6" style={{fontWeight: 900}}>Notificari</Typography>}/>
                </ListItem>
                {
                    notifications.map(notification =>
                        <MenuItem key={notification.exId}
                                  onClick={() => goToExercise(notification, history, closeNotification,
                                      unreadCount, setUnreadCount, openSnackbar)}>
                            <ListItemAvatar>
                                {notification.userPhotoUrl ?
                                    <Avatar className={classes.avatar} alt="Avatar"
                                            src={notification.userPhotoUrl}/> :
                                    <PersonIcon/>
                                }
                            </ListItemAvatar>
                            <ListItemText primary={generateNotificationText(notification)}
                                          secondary={humanizeDate(notification.created)}/>
                        </MenuItem>)
                }
            </Menu>}
        </React.Fragment>
    );
};

function goToExercise(notification, history, closeNotification, unreadCount, setUnreadCount, openSnackbar) {
    const {exId} = notification;
    closeNotification();
    if (notification.read === false) {
        setUnreadCount(--unreadCount);
        notification.read = true;

        console.log(notification);
        firebase.firestore().collection('notifications')
            .doc(notification.id)
            .update({read: true})
            .then(() => console.log('notification read'))
            .catch(err => openSnackbar(err.message))
    }
    history.push(EXERCISE_DETAILS_PREFIX + exId);
}

function fetchNotifications(user, setNotifications, openSnackbar, setUnreadCount) {
    if (!user) return;
    const userId = user.uid;
    userId && firebase.firestore().collection('notifications')
        .where('userUUID', "==", userId)
        .orderBy('created', 'desc')
        .limit(10)
        .onSnapshot(snap => {
            let notifications = snap.docs.map(doc => doc.data());
            setNotifications(notifications);
            setUnreadCount(notifications.filter(n => n.read === false).length);
        })
}

function generateNotificationText(notification) {
    const {type, userDisplayName} = notification;
    switch (type) {
        case 'SOLUTION_ADDED':
            return (
                <StyledBox fontWeight={notification.read ? 'fontWeightRegular' : 'fontWeightBold'}>
                    <Typography noWrap
                                style={{fontWeight: notification.read ? 'inherit' : 700}}>
                        {userDisplayName + ' a adaugat o noua solutie la exercitiu'}
                    </Typography>
                </StyledBox>
            );
        default:
            return <Box>{'S-a facut o modificare la un exercitiu'}</Box>;
    }

}


export default UserNotifications;
