import React, {useEffect, useState} from "react";
import {styled} from '@mui/material/styles';
import {connectSearchBox} from 'react-instantsearch-dom';
import {alpha} from "@mui/material";
import InputBase from "@mui/material/InputBase";
import Grid from "@mui/material/Grid";
import SearchIcon from "@mui/icons-material/Search";
import {useHistory, withRouter} from "react-router-dom";
import {SEARCH_EXERCISE_PATTERN, SEARCH_EXERCISE_PREFIX} from "../constants";
import {matchLocationPatternParams} from "../utils/common";
import {content, track} from "../utils/analytics";

const PREFIX = 'SearchBox';

const classes = {
    search: `${PREFIX}-search`,
    searchIcon: `${PREFIX}-searchIcon`,
    inputRoot: `${PREFIX}-inputRoot`,
    inputInput: `${PREFIX}-inputInput`
};

const Root = styled(Grid)((
    {
        theme
    }
) => ({
    [`&.${classes.search}`]: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        border: '1px solid ' + theme.palette.primary.dark,
        backgroundColor: alpha(theme.palette.primary.dark, 0.15),
        '&:hover': {
            backgroundColor: alpha(theme.palette.primary.dark, 0.25),
        },
        marginLeft: 0,
        width: '100%',
        height: '38px',
        minWidth: '12ch',
        color: theme.palette.text.primary
    },
    [`& .${classes.searchIcon}`]: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    [`& .${classes.inputRoot}`]: {
        color: 'inherit',
        width: '100%'
    },

    [`& .${classes.inputInput}`]: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        color: theme.palette.text.primary
    }
}));

const SearchBox = ({currentRefinement, refine, location}) => {

    let history = useHistory();
    const params = matchLocationPatternParams(location.pathname, SEARCH_EXERCISE_PATTERN);
    const [searchValue, setSearchValue] = useState(currentRefinement || (params && params.searchValue) || '');

    useEffect(() => {
        refine(searchValue);
        // eslint-disable-next-line
    }, []);

    return (
        <Root className={classes.search} container alignContent="center">
            <div className={classes.searchIcon}>
                <SearchIcon
                    fontSize="small"
                    sx={{
                        color: (theme) =>
                            theme.palette.mode === 'dark' ? theme.palette.grey[500] : theme.palette.primary[500],
                    }}
                />
            </div>
            <InputBase
                size="small"
                value={searchValue}
                placeholder="Cauta..."
                classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                }}
                onChange={e => {
                    const searchValue = e.target.value;
                    setSearchValue(searchValue);
                    track(content.searchExercise, {value: searchValue});
                }}
                inputProps={{'aria-label': 'search'}}
                onKeyPress={(e) => {
                    if (e && e.key === 'Enter') {
                        refine(e.target.value);
                        history.push(SEARCH_EXERCISE_PREFIX + searchValue);
                    }
                }}
            />
        </Root>
    );
};

export default withRouter(connectSearchBox(SearchBox));
