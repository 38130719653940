import {convertFromRaw, convertToRaw, EditorState} from "draft-js";
import _ from "lodash";
import moment from "moment";
import createMathjaxPlugin from 'draft-js-mathjax-plugin'
import createImagePlugin from "draft-js-image-plugin";
import {useEffect, useRef} from "react";
import {matchPath} from "react-router-dom";


const ADMIN_UUIDS = ['p4jBQdV4SQMp0CiF30dM6Yvt4fw1'];

export function sortBySortParam(a, b) {
    // sorting descending based on sorting index
    if (a.sort < b.sort) {
        return 1;
    }
    if (a.sort > b.sort) {
        return -1;
    }
    return 0;
}

export const isEmptyDraftJs = (rawState) => {
    if (!rawState || _.isEmpty(rawState)) { // filter undefined and {}
        return true;
    }
    const contentState = convertFromRaw(rawState);
    // should have at least 3 chars longs, otherwise it is considered empty
    return !(contentState.hasText() && (contentState.getPlainText().length >= 3));
};

export const isEmptyEditorState = (editorState) => {
    return isEmptyDraftJs(convertToRaw(editorState.getCurrentContent()));
}

export const extractUser = (user) => {
    return {
        userUUID: user.uid,
        userDisplayName: user.displayName,
        userPhotoUrl: user.photoURL,
    }
}

export function getEditorStateFromString(stringEditorState) {
    if (!stringEditorState) return EditorState.createEmpty();
    return EditorState.createWithContent(convertFromRaw(JSON.parse(stringEditorState)));
}

export function getStringFromEditorState(editorState) {
    return JSON.stringify(convertToRaw(editorState.getCurrentContent()))
}

export function humanizeDate(created) {
    if (!created) return null;
    let date = typeof created.toDate === "function" ? created.toDate() : created;
    let diffMillis = new Date() - new Date(date);
    return moment.duration(diffMillis).humanize();
}

export function isSameUserOrAdmin(user, solutionOrQuestion) {
    if (!user) return false;
    if (isAdmin(user)) return true;
    if (!solutionOrQuestion) return false;

    return (user && user.uid) === solutionOrQuestion.userUUID;
}

export function isAdmin(user) {
    if (!user) return false;
    return ADMIN_UUIDS.includes(user.uid);
}


export const Show = ({children, value}) => {
    if (!value) return null;
    return children;
}

export function createTexEditorPlugins() {
    return () => {
        const mathjaxPlugin = createMathjaxPlugin(/* optional configuration object */);
        const imagePlugin = createImagePlugin();

        const plugins = [mathjaxPlugin, imagePlugin];
        return {
            plugins,
        };
    };
}

export function uuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        // eslint-disable-next-line no-mixed-operators
        let r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

export function mergeTexts(editorState) {
    const mergedEditorState = [];
    let lastType = null;
    let currentType = null;
    editorState.forEach(state => {
        currentType = state.type;
        if (lastType === currentType && lastType !== null && lastType === 'text') {
            // merge current with last
            let lastIndex = mergedEditorState.length - 1;
            const lastState = mergedEditorState[lastIndex];
            lastState.value = lastState.value + '\n' + state.value; // merging texts
            mergedEditorState[lastIndex] = lastState;
        } else {
            mergedEditorState.push(state)
        }
        lastType = currentType;
    })
    return mergedEditorState;
}


export const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms))

export const useTraceUpdate = (props) => {
    const prev = useRef(props);
    useEffect(() => {
        const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
            if (prev.current[k] !== v) {
                ps[k] = [prev.current[k], v];
            }
            return ps;
        }, {});
        if (Object.keys(changedProps).length > 0) {
            console.log('Changed props:', changedProps);
        }
        prev.current = props;
    });
}


export const matchLocationPatternParams = (pathName, pattern) => {
    if (!pathName || !pattern) return null;
    let matchResult = matchPath(pathName, {
        path: pattern,
        exact: true,
        strict: true
    });
    return matchResult && matchResult.params;
};

export const extractRequirementTitle = (requirement) => {
    if (!requirement) return null;
    let title;

    let json;
    try {
        json = JSON.parse(requirement);
    } catch (e) {
        console.log(e);
    }

    if (!json) return null;

    const blocks = json.blocks;
    const entityMap = json.entityMap;
    if (!blocks || blocks.length === 0) return null;


    let currentBlockIndex = 0;
    while (!title && currentBlockIndex <= blocks.length - 1) {
        // 1. look over current block and check text
        const currentBlock = blocks[currentBlockIndex];
        const text = currentBlock.text.trim();
        const textLength = text.length;

        if (text && textLength > 5) {
            // 2. if available mark it as the title
            if (text.length > 512) {
                title = text.substring(0, 512);
            } else {
                title = text;
            }
        } else {
            // 3. if not available check entityMap at the same position
            //TODO simplify using LoDash
            if (!entityMap) continue;
            const entity = entityMap[currentBlockIndex++];
            if (!entity) continue;
            const entityData = entity.data;
            if (!entityData) continue;
            const teX = entityData.teX;
            if (!teX) continue;

            const textToFind1 = "text{";
            const textToFind2 = "text {";


            //TODO better removing latex commands
            const firstIndexOfText = teX.indexOf(textToFind1) === -1 ? teX.indexOf(textToFind2) : teX.indexOf(textToFind1);
            const nextAccoladeIndex = teX.substring(firstIndexOfText).indexOf("}");
            if (firstIndexOfText > -1 && nextAccoladeIndex > -1) {
                title = teX.substring(firstIndexOfText + teX.indexOf(textToFind1) === -1 ? textToFind2.length + 1 : textToFind1.length + 1, nextAccoladeIndex + 1);
            } else {
                title = teX.substring(0, 512);
            }

        }

        currentBlockIndex++;
    }

    return title;
}
