import 'firebase/firebase-firestore';
import {content, track} from "../utils/analytics";
import settings from "../settings";
import _ from "lodash";

export const fetchSimilarExercises = async (tags) => {
    if (!tags || tags.length === 0) return new Promise((resolve, reject) => resolve([]));

    return new Promise((resolve, reject) => {
        settings.searchClient.search(
            tags.split(',').map(tag => {
                return {
                    indexName: settings.algoliaIndex,
                    query: tag,
                    hitsPerPage: 5
                }
            })
        ).then(resp => {
            const exIds = _.get(resp, 'results[0].hits', []).map(hit => hit.id);
            resolve(exIds);
        }).catch(err => {
            console.log(err);
            track(content.error, err);
            reject(err);
        });
    });

}


