import React, {useEffect, useState} from "react";
import {styled} from '@mui/material/styles';
import {Avatar, Card, CardContent, CardMedia, Grid, Typography} from "@mui/material";
import UserProfile from "./UserProfile";
import _ from "lodash";
import {fetchUser} from "../../service/user.service";
import {Link, useParams} from "react-router-dom";
import EmptyState from "../../layout/EmptyState/EmptyState";
import FindIcon from "@mui/icons-material/FindInPage";
import Fab from "@mui/material/Fab";
import {Show} from "../../utils/common";
import {HOME} from "../../constants";
import HomeIcon from "@mui/icons-material/Home";
import {UserActivity} from "./UserActivity";
import {content, track} from "../../utils/analytics";

const PREFIX = 'ViewProfilePage';

const classes = {
    media: `${PREFIX}-media`,
    avatar: `${PREFIX}-avatar`,
    avatarContainer: `${PREFIX}-avatarContainer`,
    name: `${PREFIX}-name`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
    {
        theme
    }
) => ({
    [`& .${classes.media}`]: {height: 140},

    [`& .${classes.avatar}`]: {
        height: theme.spacing(13),
        width: theme.spacing(13),
        border: "5px solid",
        borderColor: theme.palette.background.paper
    },

    [`& .${classes.avatarContainer}`]: {
        position: "relative",
        bottom: theme.spacing(10),
    },

    [`& .${classes.name}`]: {
        fontWeight: 800
    },

}));

export const ViewProfilePage = ({
                                    authUser,
                                    isSignedIn,
                                    onMessage,
                                    onSignInClick,
                                    isPerformingAuthAction,
                                    onChangeDisplayNameClick
                                }) => {
    const [persistedUser, setPersistedUser] = useState({});
    const [userFetched, setUserFetched] = useState(false);
    const {userId} = useParams();

    useEffect(() => {
        fetchUser(userId).then(doc => {
            if (doc.exists) {
                track(content.viewProfile, {
                    userProfileId: userId,
                    authUserId: authUser.uid,
                    authUserDisplayName: authUser.userDisplayName
                })
                const docData = doc.data();
                setPersistedUser(docData);
            }
        }).catch(err => {
            track(content.error, err)
            console.log(err);
        })
            .finally(() => setUserFetched(true))
        // eslint-disable-next-line
    }, [userId]);

    if (!isSignedIn) {
        setTimeout(() => onSignInClick(), 500);
        return null;
    }

    const {photoURL, displayName} = persistedUser;
    const userExist = !_.isEmpty(persistedUser);

    return (
        <Root>
            <Show value={!userExist && userFetched}>
                <EmptyState
                    icon={<FindIcon style={{fontSize: 80}} color="action"/>}
                    title="Nu am gasit profilul"
                    description="se pare te-ai ratacit"
                    button={
                        <Fab style={{marginTop: 12}} color="primary" component={Link} to={HOME}
                             variant="extended">
                            <HomeIcon style={{marginRight: 5}}/>Intoarce-te acasa
                        </Fab>
                    }
                />
            </Show>
            <Show value={userExist}>
                <Card
                    sx={{
                        backgroundColor: (theme) => (theme.palette.mode === 'light' ? theme.palette.grey["50"] : theme.palette.background.default
                        )
                    }}>
                    <CardMedia className={classes.media} image="/math_pic.png"/>
                    <CardContent>
                        <Grid container spacing={1} className={classes.avatarContainer}>
                            <Grid item container alignItems="center" direction="column">
                                <Grid item>
                                    <Avatar alt="Avatar" src={photoURL} className={classes.avatar}/>
                                </Grid>
                                <Grid item>
                                    <Typography variant="h5" style={{fontWeight: 800}}>{displayName}</Typography>
                                </Grid>
                            </Grid>
                            <Grid item container xs={12} md={6} direction="column">
                                <Grid item xs={12}>
                                    <UserProfile user={persistedUser} authUser={authUser}
                                                 isPerformingAuthAction={isPerformingAuthAction}
                                                 onChangeDisplayNameClick={onChangeDisplayNameClick}/>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <UserActivity user={persistedUser} onMessage={onMessage}/>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Show>
        </Root>
    );
}
