import firebase from 'firebase/app';
import 'firebase/firebase-firestore';
import {NAVIGATION} from "../content/Explore/ExerciseNavigator";
import {content, track} from "../utils/analytics";

export const NO_MORE = 'NO_MORE';
export const fetchExercise = (exerciseId, exercise, setExercise, onMessage) => {
    if (!exerciseId) return;
    firebase.firestore().collection('exercise').doc(exerciseId).get()
        .then(doc => {
            if (doc.exists) {
                const newExercise = {...exercise, id: doc.id, ...doc.data()};
                firebase.firestore().collection('exercise').doc(exerciseId).collection('solutions').get()
                    .then(async res => {
                            if (res && res.docs && res.docs.length > 0) {
                                const solutions = res.docs.map(doc => {
                                    return {id: doc.id, ...doc.data()}
                                });

                                const solutionsWithVotes = [];
                                for (const sol of solutions) {
                                    const votesRes = await firebase.firestore().collection('exercise').doc(exerciseId).collection('solutions').doc(sol.id).collection('votes').get();
                                    let votes = votesRes.docs.map(doc => doc.data());
                                    solutionsWithVotes.push({...sol, votes});
                                }
                                setExercise({...newExercise, solutions: solutionsWithVotes}); // exercise with solution
                            } else {
                                setExercise({...newExercise, solutions: []}); // exercise without solution
                            }
                        }
                    )
                    .catch(err => {
                        console.log(err);
                        onMessage(err && err.message);
                        track(content.error, err);
                    })
            } else {
                let message = `Exercitiul: ${exerciseId} nu a fost gasit`;
                console.error(message);
                track(content.error, {text: message})
            }

        })
        .catch(err => {
            console.log(err);
            onMessage(err && err.message);
            track(content.error, err);
        });
}

export const fetchExercises = async (navigation, startAfter, limit = 4) => {
    const ref = firebase.firestore().collection('exercise')
    let query = ref
        .where('hasSolution', '==', navigation === NAVIGATION.unanswered ? false : true);

    query = query
        .orderBy('created', 'desc');

    if (startAfter) {
        return query.startAfter(startAfter).limit(limit).get();
    }
    return query.limit(limit).get();
}

export const incrementViewCount = (exId) => {
    if (!exId) return;
    const increment = firebase.firestore.FieldValue.increment(1);
    const exRef = firebase.firestore().collection('exercise').doc(exId);
    return exRef.update({reads: increment})
}

export const fetchUserAskedExercises = (userId, startAfter, limit = 5) => {
    if (!userId || startAfter === NO_MORE) return new Promise((resolve, reject) => setTimeout(resolve([]), 300));
    let query = firebase.firestore().collection('exercise')
        .where('userUUID', '==', userId)
        .orderBy('created', 'desc');

    if (startAfter) return query.startAfter(startAfter).limit(limit).get();

    return query.limit(limit).get();
}

export const fetchUserFollowedExercises = (userId, startAfter, limit = 5) => {
    if (!userId || startAfter === NO_MORE) return new Promise((resolve, reject) => setTimeout(resolve([]), 300));

    let query = firebase.firestore().collection('exercise')
        .where('followedBy', "array-contains", userId)
        .orderBy('created', 'desc');

    if (startAfter) return query.startAfter(startAfter).limit(limit).get();

    return query.limit(limit).get();
}

export const fetchUserRespondedExercises = (userId, startAfter, limit = 5) => {
    if (!userId || startAfter === NO_MORE) return new Promise((resolve, reject) => setTimeout(resolve([]), 300));

    const query = firebase.firestore().collectionGroup('solutions')
        .where('userUUID', '==', userId)
        .orderBy('created', 'desc')

    if (startAfter) return query.startAfter(startAfter).limit(limit).get();
    return query.limit(limit).get();
}


export const saveFollowedBy = async (exId, uid) => {
    if (!exId || !uid) return new Promise((resolve, reject) => reject('invalid arguments'));

    return firebase.firestore().collection("exercise").doc(exId)
        .update({
            followedBy: firebase.firestore.FieldValue.arrayUnion(uid)
        })
}

export const removeFollowedBy = async (exId, uid) => {
    if (!exId || !uid) return new Promise((resolve, reject) => reject('invalid arguments'));

    return firebase.firestore().collection("exercise").doc(exId)
        .update({
            followedBy: firebase.firestore.FieldValue.arrayRemove(uid)
        })
}


export function fetchExercisesSummaryById(exId) {
    if (!exId) return new Promise(() => []);

    return new Promise((resolve, reject) => {
        firebase.firestore().collection("exercise")
            .doc(exId)
            .get()
            .then(res => resolve(res.exists ? res.data() : null))
            .catch(err => reject(err));
    });
}