import React from "react";
import { styled } from '@mui/material/styles';
import List from "@mui/material/List";
import Solution from "./Solution";
import {getStringFromEditorState, isEmptyDraftJs} from "../utils/common";
import {EditorState} from "draft-js";
import Typography from "@mui/material/Typography";
import _ from "lodash";

const PREFIX = 'Solutions';

const classes = {
    answers: `${PREFIX}-answers`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
    {
        theme
    }
) => ({
    [`& .${classes.answers}`]: {
        marginTop: theme.spacing(3)
    }
}));

export {};

export default function Solutions({ex, onMessage, user, onChanged, onSignInClick}) {
    const solutions = ex.solutions ? ex.solutions : [];
    const concatenatedSolutions = [...solutions, {content: ex.solution}];



    if (!isEmptyDraftJs(JSON.parse(ex.solution))) {
        // add one empty call to answer
        let emptySolution = {content: getStringFromEditorState(EditorState.createEmpty())};
        concatenatedSolutions.push(emptySolution);
    }

    concatenatedSolutions.sort((b, a) => {
        const votesA = a.votes ? a.votes.length : 0;
        const votesB = b.votes ? b.votes.length : 0;
        if (votesA > votesB) return 1;
        if (votesA < votesB) return -1;
        return 0;
    });
    const EMPTY_SOLUTION = 1;

    let answersCount = concatenatedSolutions.length - EMPTY_SOLUTION;

    return (
        <Root>
            <Typography className={classes.answers}
                        variant="h5">{answersCount} {answersCount === 1 ? ' Raspuns' : ' Raspunsuri'}</Typography>
            <List>
                {concatenatedSolutions.map((solution, index) => {
                        let last = index === concatenatedSolutions.length - 1;
                        return <React.Fragment key={solution.content}>
                            <Solution key={index} solution={solution} onMessage={onMessage} index={index} ex={ex}
                                      isBestSolution={index === 0 && !last && _.get(solution, 'votes', '').length > 0}
                                      isLast={last} user={user}
                                      onChanged={onChanged} onSignInClick={onSignInClick}/>
                        </React.Fragment>;
                    }
                )}
            </List>
        </Root>
    );
}
