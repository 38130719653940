// eslint-disable-next-line no-unused-vars
import React from "react";
import {Badge} from "@mui/material";
import {styled} from "@mui/material/styles";

const PREFIX = 'StyledBadge';
const classes = {
    badge: `${PREFIX}-badge`
}

export const StyledBadge = styled(Badge)(({theme}) => ({
    [`& .${classes.badge}`]: {
        right: -5,
        top: 12,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
    }
}));