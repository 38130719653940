import React, {useState} from 'react';
import {styled} from '@mui/material/styles';
import {Grid} from "@mui/material";
import ExerciseNavigator, {NAVIGATION} from "./ExerciseNavigator";
import {ExerciseExplorer} from "./ExerciseExplorer";

const PREFIX = 'Explore';

const classes = {
    root: `${PREFIX}-root`,
    container: `${PREFIX}-container`,
    header: `${PREFIX}-header`
};

const StyledGrid = styled(Grid)((
    {
        theme
    }
) => ({
    [`& .${classes.root}`]: {
        flexGrow: 1
    },

    [`& .${classes.header}`]: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    }
}));

export const Explore = ({onMessage, user}) => {
    const [navigation, setNavigation] = useState(NAVIGATION.newest);
    const [loading, setLoading] = useState(false);



    return (
        <StyledGrid container justifyContent="center" className={classes.container}>
            <Grid item xs={12} lg={8} className={classes.header}>
                <ExerciseNavigator navigation={navigation}
                                   onChanged={setNavigation}
                                   disabled={loading}/>
            </Grid>
            <Grid item xs={12} lg={8}>
                <ExerciseExplorer onMessage={onMessage}
                                  user={user}
                                  navigation={navigation}
                                  onLoading={setLoading}/>
            </Grid>
        </StyledGrid>
    );
}
