import React from "react";

export default function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            style={{overflow: 'auto'}}
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <React.Fragment>
                    {children}
                </React.Fragment>
            )}
        </div>
    );
}
