import React from "react";
import {fetchExercises} from "../../service/exercise.service";
import _ from "lodash";
import {Exercise} from "../ExerciseListContent/Exercise";
import InfiniteScroll from 'react-infinite-scroller';
import {CircularProgress, Grid} from "@mui/material";
import {content, track} from "../../utils/analytics";

const LIMIT = 5;

export class ExerciseExplorer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            exerciseIds: [],
            startAfter: null,
            hasMore: true,
        }
        this.loadingStack = [];
        this.isFetching = false;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const prevNav = prevProps.navigation;
        const currentNav = this.props.navigation;
        const navigationChanged = prevNav !== currentNav;
        if (navigationChanged) {
            this.setState({exerciseIds: [], startAfter: null, hasMore: true});
        }
    }

    componentDidMount() {
        const {user} = this.props;
        track(content.explore, {userId: user?.uid, userDisplayName: user?.displayName})
    }

    loadFunc() {
        const {startAfter, exerciseIds} = this.state;
        const {navigation, onLoading} = this.props;

        if (this.loadingStack.length === 0) {
            onLoading(true);
        }

        this.loadingStack.push(1);
        fetchExercises(navigation, startAfter, LIMIT)
            .then(resp => {
                let startAfter;
                let hasMore = true;

                let docs = _.get(resp, 'docs', []);
                let fetchedExerciseIds = docs.map((doc) => doc.id);
                if (fetchedExerciseIds.length === 0) {
                    hasMore = false;
                }

                if (fetchedExerciseIds.length < LIMIT) {
                    hasMore = false;
                }

                startAfter = docs[docs.length - 1];
                let alreadyHasSameExId = exerciseIds.includes(fetchedExerciseIds[0]);
                if (alreadyHasSameExId) {
                    hasMore = false;
                }

                const newExerciseIds = [...exerciseIds, ...fetchedExerciseIds];
                this.setState(
                    {
                        exerciseIds: newExerciseIds,
                        startAfter: startAfter,
                        hasMore: hasMore
                    });

                this.loadingStack.pop();
                if (this.loadingStack.length === 0) {
                    onLoading(false);
                }
            }).catch(err => console.error(err));
    }

    render() {
        const {onMessage, user} = this.props;
        const {hasMore, exerciseIds} = this.state;

        return (
            <InfiniteScroll
                pageStart={0}
                hasMore={hasMore}
                loadMore={this.loadFunc.bind(this)}
                threshold={10}
                loader={
                    <Grid key={0} container justifyContent="center" direction="row" style={{marginTop: "12px"}}>
                        <Grid item><CircularProgress/></Grid>
                    </Grid>
                }
            >
                {
                    exerciseIds.map(id =>
                        <Exercise key={id}
                                  exId={id}
                                  onMessage={onMessage}
                                  user={user}
                                  isNew={false}
                                  showDetails={false}
                        />
                    )
                }
            </InfiniteScroll>
        );
    }
}
