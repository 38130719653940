import React from "react";
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import CodeIcon from '@mui/icons-material/Code';
import {StyleControlButton} from "./StyleControlButton";


export const INLINE_STYLES = [
    {icon: <FormatBoldIcon/>, style: 'BOLD', tooltip: 'Bold'},
    {icon: <FormatItalicIcon/>, style: 'ITALIC', tooltip: 'Italic'},
    {icon: <FormatUnderlinedIcon/>, style: 'UNDERLINE', tooltip: 'Subliniaza'},
    {icon: <CodeIcon/>, style: 'CODE', tooltip: 'Cod'},
];

export const InlineStyleControls = ({editorState, onToggle}) => {
    const currentStyle = editorState.getCurrentInlineStyle();
    return (
        <React.Fragment>
            {INLINE_STYLES.map(type => (
                <StyleControlButton
                    key={type.style}
                    active={currentStyle.has(type.style)}
                    icon={type.icon}
                    onToggle={onToggle}
                    style={type.style}
                    tooltip={type.tooltip}
                />
            ))}
        </React.Fragment>
    );
}
