import React from "react";
import {IconButton, Tooltip} from "@mui/material";


export const StyleControlButton = ({active, icon, onToggle, style, tooltip}) => {
    return (
        <Tooltip title={tooltip}>
            <IconButton
                size="small"
                color={active ? 'primary' : 'default'}
                onMouseDown={(e) => {
                    e.preventDefault();
                    onToggle(style);
                }}>
                {icon}
            </IconButton>
        </Tooltip>)
};
