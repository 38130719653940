import React from "react";
import { styled } from '@mui/material/styles';
import PersonIcon from "@mui/icons-material/Person";
import EditIcon from "@mui/icons-material/Edit";
import EmailIcon from "@mui/icons-material/Email";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import moment from 'moment';
import {
    Avatar,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    Paper,
    Tooltip
} from "@mui/material";
import {Show} from "../../utils/common";

const PREFIX = 'UserProfile';

const classes = {
    list: `${PREFIX}-list`
};

const StyledPaper = styled(Paper)((
    {
        theme
    }
) => ({
    [`& .${classes.list}`]: {
        flexGrow: 1
    }
}));

export default function UserProfile({user, authUser, isPerformingAuthAction, onChangeDisplayNameClick}) {
    return (
        <StyledPaper>
            <List className={classes.list}>
                <ListItem>
                    <ListItemAvatar>
                        <Avatar>
                            <Tooltip title="Nume">
                                <PersonIcon/>
                            </Tooltip>
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="Nume" secondary={user.displayName}/>
                    <Show value={user.uid === authUser.uid}>
                        <ListItemSecondaryAction>
                            <Tooltip title="Schimba numele">
                                <IconButton
                                    disabled={isPerformingAuthAction}
                                    onClick={onChangeDisplayNameClick}
                                    size="large">
                                    <EditIcon/>
                                </IconButton>
                            </Tooltip>
                        </ListItemSecondaryAction>
                    </Show>
                </ListItem>
                <ListItem>
                    <ListItemAvatar>
                        <Avatar>
                            <Tooltip title="E-mail address">
                                <EmailIcon/>
                            </Tooltip>
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={user.email}/>
                </ListItem>
                <ListItem>
                    <ListItemAvatar>
                        <Avatar>
                            <Tooltip title="Ultima autentificare">
                                <AccessTimeIcon/>
                            </Tooltip>
                        </Avatar>
                    </ListItemAvatar>

                    <ListItemText primary="Ultima autentificare"
                                  secondary={moment(user.lastSignInTime).format('LLLL')}/>
                </ListItem>
                <ListItem>
                    <ListItemAvatar>
                        <Avatar>
                            <Tooltip title="Membru din">
                                <AccessTimeIcon/>
                            </Tooltip>
                        </Avatar>
                    </ListItemAvatar>

                    <ListItemText primary="Membru din"
                                  secondary={moment(user.creationTime).format('LLLL')}/>
                </ListItem>
            </List></StyledPaper>
    );
}
