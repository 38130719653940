import React, { Component } from 'react';

import { styled } from '@mui/material/styles';

import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';

const PREFIX = 'EmptyState';

const classes = {
  center: `${PREFIX}-center`
};

const Root = styled('div')({
  [`&.${classes.center}`]: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center'
  }
});

class EmptyState extends Component {
  render() {

    // Properties
    const { icon, title, description, button } = this.props;

    return (
      <Root className={classes.center}>
        {icon}
        {title && <Typography color="textSecondary" variant="h4">{title}</Typography>}
        {description && <Typography color="textSecondary" variant="subtitle1">{description}</Typography>}
        {button}
      </Root>
    );
  }
}

EmptyState.propTypes = {
  classes: PropTypes.object.isRequired,

  icon: PropTypes.element,
  title: PropTypes.string,
  description: PropTypes.string,
  button: PropTypes.element
};

export default (EmptyState);