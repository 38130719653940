import React, {useContext, useEffect, useState} from "react";
import {Grid, Typography} from "@mui/material";
import {fetchSimilarExercises} from "../../service/index.service";
import {AppContext} from "../../App/App";
import PreviewExercise from "./PreviewExercise";

export default function SimilarExercises({tags, exId}) {
    const [similarExIds, setSimilarExIds] = useState([]);
    const {openSnackbar} = useContext(AppContext);

    useEffect(() => {
        fetchSimilarExercises(tags)
            .then(similarExIds => setSimilarExIds(similarExIds.filter(eId => eId !== exId)))
            .catch(e => openSnackbar(e.message));
    }, [tags, openSnackbar, exId]);

    if (!tags || similarExIds.length === 0) return null;

    return (
        <Grid container spacing={2} sx={{marginTop: (theme) => theme.spacing(3)}}>
            <Grid item>
                <Typography variant="h3">Intrebari similare:</Typography>
            </Grid>
            {
                similarExIds.map(exId =>
                    <Grid item xs={12} key={exId}>
                        <PreviewExercise exId={exId}/>
                    </Grid>)
            }
        </Grid>);
}