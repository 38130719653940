import React, { Component } from 'react';

import { styled } from '@mui/material/styles';

import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';

import Fab from '@mui/material/Fab';

import FindIcon from '@mui/icons-material/FindInPage';
import HomeIcon from '@mui/icons-material/Home';

import EmptyState from '../../layout/EmptyState/EmptyState';

const PREFIX = 'NotFoundContent';

const classes = {
  emptyStateIcon: `${PREFIX}-emptyStateIcon`,
  button: `${PREFIX}-button`,
  buttonIcon: `${PREFIX}-buttonIcon`
};

const StyledFab = styled(Fab)((
  {
    theme
  }
) => ({
  [`& .${classes.emptyStateIcon}`]: {
    fontSize: theme.spacing(12)
  },

  [`&.${classes.button}`]: {
    marginTop: theme.spacing(1)
  },

  [`& .${classes.buttonIcon}`]: {
    marginRight: theme.spacing(1)
  }
}));

class NotFoundContent extends Component {
  render() {
    return (
      <EmptyState
        icon={<FindIcon className={classes.emptyStateIcon} color="action" />}
        title="Content Not Found"
        description="The requested URL was not found on this server"
        button={
          <StyledFab className={classes.button} color="secondary" component={Link} to="/" variant="extended">
            <HomeIcon className={classes.buttonIcon} /> Go Home
          </StyledFab>
        }
      />
    );
  }
}

NotFoundContent.propTypes = {
  classes: PropTypes.object.isRequired
};

export default (NotFoundContent);