import React, {useRef, useState} from "react";
import { styled } from '@mui/material/styles';
import Editor from 'draft-js-plugins-editor'
import {createTexEditorPlugins, Show} from "../utils/common";
import {RichUtils} from 'draft-js';
import {Divider} from "@mui/material";
import {getBlockStyle, styleMap} from "../utils/EditorUtils";
import {InlineStyleControls} from "./editor/InlineStyleControls";
import {BlockStyleControls} from "./editor/BlockStyleControls";
import FunctionsIcon from '@mui/icons-material/Functions';
import {StyleControlButton} from "./editor/StyleControlButton";
import {LatexCheatSheetDialog} from "./editor/LatexCheatSheetDialog";

const PREFIX = 'AdvancedEditor';

const classes = {
    dividerSpacing: `${PREFIX}-dividerSpacing`,
    editorSpacing: `${PREFIX}-editorSpacing`
};

const Root = styled('div')((
    {
        theme
    }
) => ({
    [`& .${classes.dividerSpacing}`]: {
        marginTop: theme.spacing(1),
    },

    [`& .${classes.editorSpacing}`]: {
        marginTop: theme.spacing(1)
    }
}));


export const AdvancedEditor = (props) => {
    const {readOnly, placeholder, editorState, onChange} = props;
    const [{plugins}] = useState(createTexEditorPlugins());
    const editorRef = useRef(null);
    const [latexDialogOpen, setLatexDialogOpen] = useState(false);

    const focus = () => editorRef.current && editorRef.current.focus();

    const handleKeyCommand = (command, editorState) => {
        const newState = RichUtils.handleKeyCommand(editorState, command);
        if (newState) {
            onChange(newState);
            return 'handled';
        }
        return 'not-handled';
    }

    return (
        <Root onClick={focus}>
            <Show value={!readOnly}>
                <InlineStyleControls
                    editorState={editorState}
                    onToggle={style => onChange(RichUtils.toggleInlineStyle(editorState, style))}
                />
                <BlockStyleControls
                    editorState={editorState}
                    onToggle={style => onChange(RichUtils.toggleBlockType(editorState, style))}
                />
                <StyleControlButton
                    tooltip="Lista functii Latex"
                    onToggle={() => setLatexDialogOpen(true)}
                    icon={<FunctionsIcon/>}
                />
                <Divider className={classes.dividerSpacing} light={true}/>
            </Show>
            <div className={classes.editorSpacing}>
                <Editor
                    customStyleMap={styleMap}
                    blockStyleFn={getBlockStyle}
                    readOnly={readOnly}
                    placeholder={placeholder}
                    editorState={editorState}
                    onChange={(editorState) => onChange(editorState)}
                    plugins={plugins}
                    handleKeyCommand={handleKeyCommand}
                    ref={editorRef}
                />
            </div>
            <LatexCheatSheetDialog open={latexDialogOpen} onClose={() => setLatexDialogOpen(false)}/>
        </Root>
    );
}
