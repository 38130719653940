/* eslint-disable no-unused-vars */

import algoliasearch from "algoliasearch/lite";
import orange from "@mui/material/colors/orange";
import gray from "@mui/material/colors/grey";

/* eslint-enable no-unused-vars */

const settings = {
  title: 'MathClub',

  theme: {
    primaryColor: {
      name: 'orange',
      import: orange[400]
    },
    secondaryColor: {
      name: 'gray',
      import: gray[400]
    },
    mode: 'light'
  },

  credentials: {
    firebase: {
      apiKey: "AIzaSyA8zuy6_XXYfn9DYQehL1KeUqzo5MDISKY",
      authDomain: "mathclub-dee20.firebaseapp.com",
      databaseURL: "https://mathclub-dee20.firebaseio.com",
      projectId: "mathclub-dee20",
      storageBucket: "mathclub-dee20.appspot.com",
      messagingSenderId: "631894948908",
      appId: "1:631894948908:web:219509536bcc49e1",
      measurementId: "G-43DR4MLPX9",
      messaging: "BD5EAF6kpK1wmN4Lepzb96R0HncNwRwg05a_Fl77krrEhXTwWKLYgw48UURfD3l5ziGCPHHXdM5RlClwJ29aUI4"
    }
  },

  searchClient: algoliasearch(
      'C3DYERS6UL',
      'b78bffca29cef6857ec219911dab8f4a'
  ),

  algoliaIndex: window.location.hostname === "localhost" ? 'dev_MathClub' : 'mathClub'

};

export default settings;
