import React from 'react';
import { styled } from '@mui/material/styles';
import { Grid, Link, Typography } from '@mui/material';
import {ADD_EXERCISE, EXPLORE_EXERCISE} from "../constants";

const PREFIX = 'Footer';

const classes = {
    container: `${PREFIX}-container`,
    links: `${PREFIX}-links`
};

const StyledGrid = styled(Grid)((
    {
        theme
    }
) => ({
    [`&.${classes.container}`]: {
        color: theme.palette.text.primary,
        padding: theme.spacing(10)
    },

    [`& .${classes.links}`]: {
        [theme.breakpoints.down('xl')]: {
            paddingTop: theme.spacing(3),
            alignItems: "center"
        }
    }
}));


const Footer = () => {
    return (
        <StyledGrid container direction="row" className={classes.container} justifyContent="center" alignContent="center"
              alignItems="center">
            <Grid item md={6} xs={12} container alignItems="center" direction="column">
                <Typography variant="h4">MATHCLUB</Typography>
                <Typography variant="subtitle1">Site-ul tau de matematica</Typography>
            </Grid>
            <Grid className={classes.links} item md={6} xs={12} container direction="column" spacing={2}>
                <Grid item><Link href={EXPLORE_EXERCISE} color="inherit">Exploreaza</Link></Grid>
                <Grid item><Link href={ADD_EXERCISE} color="inherit">Adauga Intrebare</Link></Grid>
            </Grid>
        </StyledGrid>
    );
}

export default Footer;
