import React, {useContext} from "react";
import {styled} from '@mui/material/styles';
import CardActions from "@mui/material/CardActions";
import {ExerciseContext} from "./Exercise";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import {getStringFromEditorState, isAdmin, Show} from "../../utils/common";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import ChipTags from "./ChipTags";
import Tags from "../AddExerciseContent/Components/Tags";
import firebase from 'firebase/app';
import 'firebase/firebase-firestore';
import {FileUploadAction} from "../../components/FileUploadAction";
import {redGreenTheme} from "../../themes";
import {ThemeProvider} from "@mui/styles";
import {StyledEngineProvider} from "@mui/styled-engine";
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import {removeFollowedBy, saveFollowedBy} from "../../service/exercise.service";
import _ from 'lodash';
import {content, track} from "../../utils/analytics";

const PREFIX = 'ExerciseActions';

const classes = {
    cardActions: `${PREFIX}-cardActions`, button: `${PREFIX}-button`
};

const StyledCardActions = styled(CardActions)(({
                                                   theme
                                               }
) => ({
    [`&.${classes.cardActions}`]: {
        paddingLeft: theme.spacing(2)
    },

    [`& .${classes.button}`]: {
        margin: theme.spacing(1),
    }
}));

export {};

export function ExerciseActions() {
    const {
        ex,
        user,
        sameUserOrAdmin,
        onMessage,
        isNewExercise,
        showDetails,
        requirementEditorState,
        setRequirementEditorState,
        onExerciseChanged,
        tags,
        setTags,
        advancedEditor,
        tagsError,
        setTagsError
    } = useContext(ExerciseContext);

    const showFollowButton = user && ex && (user.uid !== ex.userUUID) && showDetails;
    const alreadyFollowed = _.get(ex, 'followedBy', []).includes(_.get(user, 'uid', ''));

    return (<StyledCardActions className={classes.cardActions}>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={redGreenTheme}>
                    <Show value={showDetails}>
                        <Show value={sameUserOrAdmin}>
                            <Tooltip title="Salveaza intrebarea">
                                <IconButton
                                    className={classes.button}
                                    aria-label="save"
                                    color="primary"
                                    onClick={saveExercise(ex, onMessage, onExerciseChanged, requirementEditorState)}
                                    size="large">
                                    <SaveIcon/>
                                </IconButton>
                            </Tooltip>
                        </Show>
                        <Show value={isAdmin(user)}>
                            <Tooltip title="Sterge intrebarea">
                                <IconButton
                                    className={classes.button}
                                    aria-label="save"
                                    color="secondary"
                                    onClick={removeExercise(ex, onMessage, onExerciseChanged)}
                                    size="large">
                                    <DeleteIcon/>
                                </IconButton>
                            </Tooltip>
                        </Show>
                    </Show>
                </ThemeProvider>
            </StyledEngineProvider>
            <Show value={showFollowButton}>
                <Tooltip title={alreadyFollowed ? 'Opreste notificarile' : 'Urmareste'}>
                    <IconButton
                        className={classes.button}
                        variant="text"
                        aria-label={alreadyFollowed ? 'Opreste' : 'urmareste'}
                        color={alreadyFollowed ? 'inherit' : 'primary'}
                        onClick={onFollowedByClicked(alreadyFollowed, ex, user, onMessage, onExerciseChanged)}
                        size="large">
                        {alreadyFollowed ? <NotificationsOffIcon/> : <NotificationsActiveIcon/>}
                    </IconButton>
                </Tooltip>
            </Show>
            <Show value={isNewExercise && advancedEditor}>
                <FileUploadAction editorState={requirementEditorState} setEditorState={setRequirementEditorState}/>
            </Show>
            <Show value={isNewExercise}>
                <Tags error={tagsError} onChange={(value) => {
                    value && value.length > 0 ? setTagsError(false) : setTagsError(true);
                    setTags(value);
                }}/>
            </Show>
            <Show value={!isNewExercise}>
                <ChipTags value={tags}/>
            </Show>
        </StyledCardActions>
    );
}

function onFollowedByClicked(alreadyFollowed, ex, user, onMessage, onExerciseChanged) {
    return () => {
        if (!ex || !user) return;
        const exId = ex.id;
        const uid = user.uid;

        if (alreadyFollowed) {
            //remove followed by
            removeFollowedBy(exId, uid)
                .then(() => {
                    track(content.removeFollowed, {exId: exId, userId: uid})
                    return onExerciseChanged();
                })
                .catch(err => {
                    track(content.error, err)
                    console.log(err);
                    onMessage('A aparut o eroare')
                });
        } else {
            saveFollowedBy(exId, uid)
                .then(() => {
                    track(content.addFollow, {exId: exId, userId: uid})
                    onExerciseChanged();
                })
                .catch(err => {
                    console.log(err);
                    track(content.error, err)
                    onMessage('A aparut o eroare')
                });
        }
    }
}

function saveExercise(ex, onMessage, onChanged, editorState) {
    return () => {
        firebase.firestore().collection('exercise').doc(ex.id)
            .set({'requirement': getStringFromEditorState(editorState)}, {merge: true})
            .then(() => {
                track(content.saveExercise, {exId: ex.id, userId: ex.userUUID})
                onMessage('Intrebarea a fost salvata');
            })
            .catch(err => {
                track(content.error, err);
                console.log(err);
                onMessage('A aparut o problema la salvarea intrebarii');
            });
    }
}

function removeExercise(ex, onMessage, onChanged) {
    return () => {
        if (!ex.id) return;
        firebase.firestore().collection('exercise').doc(ex.id)
            .delete()
            .then(() => {
                track(content.removeEx, {exId: ex.id, userId: ex.userUUID})
                onMessage('Intrebarea a fost stearsa');
            })
            .catch(err => {
                console.log(err);
                track(content.error, err)
                onMessage('A aparut o problema la stergerea intrebarii');
            });
    }
}
